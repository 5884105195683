export let Config = {
  base: {
    main: {
      path: '',
      route: null
    },
    index: {
      path: '/',
      route: null
    }
  },
  page: {
    sign: {
      in: {
        path: 'sign/in',
        route: null
      },
      up: {
        path: 'sign/up',
        route: null
      },
      reset: {
        path: 'sign/reset',
        route: null
      },
      confirm: {
        path: 'sign/confirm/:email/:auth',
        route: null
      },
      password: {
        path: 'sign/password/:email/:auth',
        route: null
      }
    },
    user: {
      likes: {
        path: 'user/likes',
        route: null
      },
      replies: {
        path: 'user/replies',
        route: null
      },
      infomation: {
        path: 'user/infomation',
        route: null
      }
    },
    eula: {
      path: 'eula',
      route: null
    },
    error: {
      path: 'error/:code',
      route: null
    },
    board: {
      detail: {
        path: 'board/:category/:boardId',
        route: null
      },
      category: {
        path: 'board/:category',
        route: null
      }
    },
    notice: {
      list: {
        path: 'notice',
        route: null
      },
      detail: {
        path: 'notice/:boardId',
        route: null
      }
    },
    contact: {
      path: 'contact',
      route: null
    },
  },
  text: 500,
  range: 5,
  index: 0,
  limit: {
    default: 50,
    category: 12
  },
  kakao: {

    key: '5ac382d1b8a547b8831b41002ef95e44',
    template: 16219
  },
  maximum: 9999,
  youtube: {
    embed: 'https://www.youtube.com/embed/',
    image: 'https://img.youtube.com/vi/',
    quality: [
      '/default.jpg',
      '/hqdefault.jpg',
      '/mqdefault.jpg',
      '/sddefault.jpg'
    ]
  },
  teads: {
    name: 'teads',
    type: 'text/javascript',
    script: '//a.teads.tv/page/106736/tag'
  },
  cookie: {
    close: 'tsm.close'
  },
  carousel: 2,
  category: {
    poll: 7,
    video: 5,
    notice: 8
  },
  firebase: {
    appId: '1:703551266350:web:497ebf1045454158',
    apiKey: 'AIzaSyBE2CXWlln_n81CIlVyk7jByBv6ptjympE',
    projectId: 'thestar-607c7',
    authDomain: 'auth.thestar.kr'
  },
  translate: {
    type: '.json',
    path: './assets/i18n/',
    base: {
      type: 1,
      name: 'Korean',
      code: 'ko'
    }
  }
}