Number.prototype.time = numberTime;
Number.prototype.empty = numberEmpty;

interface Number {

  time: typeof numberTime;
  empty: typeof numberEmpty;
}

function numberTime() {

  var sec = this % 60;
  var hour = this / 60;

  return `${ hour < 10 ? `0${ hour.toFixed() }` : hour.toFixed() }:${ sec < 10 ? `0${ sec.toFixed() }` : sec.toFixed() }`;
}

function numberEmpty() {

  return this == 0;
}