import {
  Injectable
} from '@angular/core';
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import {
  TranslatesService
} from 'src/app/services/translates/translates.service';
import { NavigationService } from '../services/navigation/navigation.service';

@Injectable()
export class TranslateGuard implements CanActivate {

  constructor(
    private translatesService: TranslatesService,
    private navigationService: NavigationService
  ) {}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Promise < boolean > {

    return new Promise( ( resolve ) => {

      Promise.resolve().then( () => {

        // get translate
        return this.translatesService.getTranslates();
      } ).then( () => {

        // get category
        return this.navigationService.getCategories();
      } ).then( () => {
        
        // do anyting
        return resolve( true );
      } );
    } );
  }
}