import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router,
  Params,
  ActivatedRoute,
} from '@angular/router';
import {
  STATUS
} from 'src/app/enum/enum';
import {
  BoardInfo,
  ReplyInfo,
  EditorInfo,
  SetMarkRequest,
  SetReplyRequest,
  GetBoardRequest,
  UpdReplyRequest,
  DelReplyRequest,
  SetReportRequest,
  GetReplyListRequest,
} from 'src/app/services/http/http.classes';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  TranslatesService
} from 'src/app/services/translates/translates.service';
import {
  NavigationService
} from 'src/app/services/navigation/navigation.service';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';
import {
  PagerData
} from 'src/app/services/pagination/pagination.service';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-notice-detail',
  templateUrl: './notice-detail.component.html',
  styleUrls: [ './notice-detail.component.css' ]
} )
export class NoticeDetailComponent implements OnInit {

  // enum
  public STATUS = STATUS;

  // public
  public req: any;
  public res: any;
  public page: any;
  public index: number;
  public subscription: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private translateService: TranslateService,
    private translatesService: TranslatesService,
    private navigationService: NavigationService
  ) {

    // init
    this.req = {

      getBoard: new GetBoardRequest(),
      setReply: new SetReplyRequest(),
      updReply: new UpdReplyRequest(),
      getReplies: new GetReplyListRequest()
    }

    this.res = {

      boardInfo: new BoardInfo(),
      replyInfos: new Array < ReplyInfo > ()
    }

    this.page = {

      content: new PagerData()
    }

    // event
    this.subscription = {

      route: this.route.params.subscribe( ( params: Params ) => {

        // set params
        this.req.getBoard.onInit( params );
        this.req.setReply.onInit( params );
        this.req.getReplies.onInit( params );

        // get board
        this.getBoard();

        // get replies
        this.getReplies();
      } ),

      translate: this.translatesService.subscription.change.subscribe( () => {

        // get board
        this.getBoard();
      } )
    }
  }

  ngOnInit() {}

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  // check 
  isStatus( editorInfo: EditorInfo, type: STATUS ) {

    return editorInfo.type == type;
  }

  isSession( replyInfo ? : ReplyInfo ) {

    if ( replyInfo ) {

      return this.navigationService.isSession( replyInfo.userInfo.userId );
    }

    return this.navigationService.isSession();
  }

  get isMark() {

    return this.res.boardInfo.marked ? true : false
  }

  // get
  get getLimit() {

    return Config.text;
  }

  // http
  setMark() {

    // check session
    if ( this.navigationService.isSession() == false ) {

      if ( confirm( this.translateService.instant( 'message.confirm.session' ) ) ) {

        return this.router.navigate( [ Config.page.sign.in.path ] );
      }

      return;
    }

    // http
    this.httpService.setMark( new SetMarkRequest( this.res.boardInfo.boardId ) ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set board
      this.res.boardInfo = response.boardInfo;

    }, error => {

      this.httpService.setError();
    } );
  }

  getBoard() {

    // request
    this.httpService.getBoard( this.req.getBoard ).subscribe( response => {

      if ( response.code ) {

        alert( response.message );

        // set referrer
        if ( history.state.navigationId > 1 ) return history.back();

        // set parent
        var href = window.location.pathname.split( '/' );

        href.pop();

        return this.router.navigate( [ href.join( '/' ) ] );
      }

      // set data
      this.res.boardInfo = response.boardInfo;

    }, error => {

      this.httpService.setError();
    } );
  }

  setReply( input: any ) {

    // check session
    if ( this.navigationService.isSession() == false ) {

      if ( confirm( this.translateService.instant( 'message.confirm.session' ) ) ) {

        return this.router.navigate( [ Config.page.sign.in.path ] );
      }

      return;
    }

    // check valid
    if ( input.value.length < 1 ) return alert( this.translateService.instant( 'message.invalid.content' ) );

    // check length
    if ( input.value.length > Config.text ) return alert( this.translateService.instant( 'message.invalid.maximum', {

      number: Config.text
    } ) );

    // request
    this.httpService.setReply( this.req.setReply ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // init 
      this.req.setReply.content = null;

      // get reply
      this.getReplies();

    }, error => {

      this.httpService.setError();
    } );
  }

  updReply( input: any ) {

    // check session
    if ( this.navigationService.isSession() == false ) {

      if ( confirm( this.translateService.instant( 'message.confirm.session' ) ) ) {

        return this.router.navigate( [ Config.page.sign.in.path ] );
      }

      return;
    }

    // check valid
    if ( input.value.length < 1 ) return alert( this.translateService.instant( 'message.invalid.content' ) );

    // check length
    if ( input.value.length > Config.text ) return alert( this.translateService.instant( 'message.invalid.maximum', {

      number: Config.text
    } ) );

    // set focus
    this.onFocus();

    // request
    this.httpService.updReply( this.req.updReply ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.replyInfos[ this.index ] = response.replyInfo;

      // set resize
      this.onResize();

    }, error => {

      this.httpService.setError();
    } );
  }

  delReply( replyInfo: ReplyInfo ) {

    // check session
    if ( this.navigationService.isSession() == false ) {

      if ( confirm( this.translateService.instant( 'message.confirm.session' ) ) ) {

        return this.router.navigate( [ Config.page.sign.in.path ] );
      }

      return;
    }

    // check confrim
    if ( confirm( this.translateService.instant( 'message.confirm.delete' ) ) == false ) return;

    // set focus
    this.onFocus();

    // request
    this.httpService.delReply( new DelReplyRequest( replyInfo.replyId ) ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // get replies
      this.getReplies( this.page.content.getIndex );

    }, error => {

      this.httpService.setError();
    } );
  }

  setReport( replyInfo: ReplyInfo ) {

    // check session
    if ( this.navigationService.isSession() == false ) {

      if ( confirm( this.translateService.instant( 'message.confirm.session' ) ) ) {

        return this.router.navigate( [ Config.page.sign.in.path ] );
      }

      return;
    }

    // check confrim
    if ( confirm( this.translateService.instant( 'message.confirm.report' ) ) == false ) return;

    // set focus
    this.onFocus();

    // request
    this.httpService.setReport( new SetReportRequest( replyInfo.replyId ) ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      alert( this.translateService.instant( 'message.report' ) );

    }, error => {

      this.httpService.setError();
    } )
  }

  getReplies( index: number = 1, limit = Config.limit.default ) {

    // set index
    this.req.getReplies.onInit( {

      index: limit * ( index - 1 ),
      limit: limit
    } )

    // request
    this.httpService.getReplyList( this.req.getReplies ).subscribe( response => {

      if ( response.code ) alert( response.message );

      // set data
      this.res.replyInfos = response.replyInfos;

      // set page
      this.page.content = new PagerData( response.pageInfo.maximum, index );

    }, error => {

      this.httpService.setError();
    } );
  }

  // action
  onReply( input: any ) {

    // check valid
    if ( input.value ) {

      return input.value.length < 1 || input.value.length > Config.text;
    }

    return true;
  }

  onUpdate( replyInfo: ReplyInfo, index: number, event: Event ) {

    // set index 
    this.index = index;

    // set reply
    this.req.updReply.onInit( replyInfo );

    // focus
    this.onFocus( event )
  }

  onFocus( event ? : Event ) {

    var element = $( '.reply .update' );

    if ( event ) {

      // init update
      $( element ).css( {

        opacity: 0,
        position: 'absolute'
      } );

      $( element ).find( 'textarea' ).css( 'height', '40px' );

      $( element ).next().css( {

        opacity: 1,
        display: 'block'
      } );

      // insert element
      $( event.target ).closest( '.reply-wrap .reply-head' ).after( element );

    } else {

      $( '.reply' ).append( element );
    }

    // resize
    this.onResize( true );
  }

  onResize( animation: boolean = false ) {

    setTimeout( () => {

      $( '.reply textarea' ).each( function() {

        // set resize
        M.textareaAutoResize( this );

        // set counter
        $( this ).characterCounter();
      } );

      // set animation
      if ( animation ) {

        var element = $( '.reply .update' );

        element.stop().animate( {

          opacity: 1
        }, 500, function() {

          $( this ).css( {

            position: 'relative'
          } );
        } );

        element.next().stop().animate( {

          opacity: 0
        }, 500, function() {

          $( this ).css( 'display', 'none' );
        } )
      }
    } );
  }
}