import {
  Pipe,
  PipeTransform
} from '@angular/core';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  DomSanitizer,
  SafeStyle
} from '@angular/platform-browser';
import {
  DatePipe
} from '@angular/common';

@Pipe( {
  name: 'src'
} )
export class Src implements PipeTransform {

  constructor( private sanitizer: DomSanitizer ) {}

  transform( data: any ) {

    if ( typeof data == 'string' ) {

      return this.sanitizer.bypassSecurityTrustResourceUrl( data );
    }

    if ( data && data.length > 0 ) {

      return this.sanitizer.bypassSecurityTrustResourceUrl( data[ 0 ].content || data[ 0 ].url );
    }

    return null;
  }
}

@Pipe( {
  name: 'url'
} )
export class Url implements PipeTransform {

  constructor( private sanitizer: DomSanitizer ) {}

  transform( data: any ) {

    if ( typeof data == 'string' ) {

      return this.sanitizer.bypassSecurityTrustStyle( `url(${ data })` );
    }

    if ( data && data.length > 0 ) {

      return this.sanitizer.bypassSecurityTrustStyle( `url(${ data[ 0 ].content || data[ 0 ].url })` );
    }

    return null;
  }
}

@Pipe( {
  name: 'open'
} )
export class Open implements PipeTransform {

  constructor( private translate: TranslateService ) {}

  transform( open: boolean, deleted: string ) {

    if ( deleted ) {

      return this.translate.instant( 'caption.column.index.delete' );
    }

    if ( !open ) {

      return this.translate.instant( 'caption.column.index.private' );
    }

    return null;
  }
}


@Pipe( {
  name: 'html'
} )
export class Html implements PipeTransform {

  constructor( private sanitizer: DomSanitizer ) {}

  transform( value: any ): SafeStyle {

    if ( value ) {

      return this.sanitizer.bypassSecurityTrustHtml( value );
    }

    return null;
  }
}

@Pipe( {
  name: 'join'
} )
export class Join implements PipeTransform {

  constructor( private sanitizer: DomSanitizer ) {}

  transform( value: any ): SafeStyle {

    if ( value ) {

      return this.sanitizer.bypassSecurityTrustHtml( value.join( '<br />' ) );
    }

    return null;
  }
}

@Pipe( {
  name: 'blank'
} )
export class Blank implements PipeTransform {

  constructor( private translate: TranslateService, private sanitizer: DomSanitizer ) {}

  transform( text: string ) {

    var instant = this.translate.instant( 'caption.column.blank' );

    return text || instant;
  }
}

@Pipe( {
  name: 'datetime'
} )
export class DateTime implements PipeTransform {

  constructor( private pipe: DatePipe = new DatePipe( 'en-US' ) ) {}

  transform( date: any, time: string, format: string ) {

    if ( typeof date == 'string' ) {

      if ( date && time ) {

        return this.pipe.transform( date.replace( ' ', 'T' ) + ' ' + time, format );
      } else if ( date ) {

        return this.pipe.transform( date.replace( ' ', 'T' ), format );
      } else if ( time ) {

        return this.pipe.transform( time, format );
      }
    } else {

      return this.pipe.transform( date, format );
    }
  }
}

@Pipe( {
  name: 'transparent'
} )
export class Transparent implements PipeTransform {

  constructor() {}

  transform( image: any ) {

    if ( typeof image == 'string' ) {

      return 'transparent';
    }

    if ( image && image.length > 0 ) {

      return 'transparent';
    }

    return null;
  }
}