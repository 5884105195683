import {
  OnInit,
  Component,
  HostListener
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  STATUS
} from 'src/app/enum/enum';
import {
  Config
} from 'src/app/app.config';
import {
  DateTime
} from 'src/app/pipe/pipe';
import {
  AdInfo,
  BoardInfo,
  PopupInfo,
  BannerInfo,
  EditorInfo,
  GetIndexRequest,
  SetPopupRequest,
  SetBannerRequest,
  GetAdRequest,
  SetAdRequest,
} from 'src/app/services/http/http.classes';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  TranslatesService
} from 'src/app/services/translates/translates.service';
import {
  NavigationService
} from 'src/app/services/navigation/navigation.service';

declare var $: any;
declare var N: any;
declare var M: any;
declare var YT: any;

@Component( {
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: [ './index.component.css' ]
} )
export class IndexComponent implements OnInit {

  // enum
  public STATUS = STATUS;

  // public
  public res: any;
  public active: any;
  public timeout: any;
  public youtube: any;
  public loading: boolean;
  public carousel: any;
  public subscription: any;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private translateService: TranslateService,
    private translatesService: TranslatesService,
    private navigationService: NavigationService
  ) {

    // init
    this.res = {

      adInfo: new AdInfo(),
      pollInfos: new Array < BoardInfo > (),
      newsInfos: new Array < BoardInfo > (),
      popupInfos: new Array < PopupInfo > (),
      videoInfos: new Array < BoardInfo > (),
      boardInfos: new Array < BoardInfo > (),
      bannerInfos: new Array < BannerInfo > ()
    }

    this.timeout = {

      popup: null,
      banner: null,
      youtube: null,
    }

    this.active = {

      banner: null,
      youtube: null,
    }

    this.loading = false;
    this.carousel = {

      popup: false,
      banner: false
    }

    // event
    this.subscription = {

      translate: this.translatesService.subscription.change.subscribe( () => {

        // get ad
        this.getAd();

        // get index
        this.getIndex();
      } )
    }
  }

  // lisner
  @HostListener( 'window:scroll', [ '$event' ] ) onScroll( event: Event ) {

    // check loading
    if ( this.loading ) return;

    // check mobile
    if ( this.navigationService.isMobile ) return;

    try {

      var half = window.innerHeight / 2;
      var rect = $( '.area-type-02 .card.active' )[ 0 ].getBoundingClientRect();

      // check half
      if ( half < rect.y ) return;

      // check status
      if (
        this.active.youtube &&
        this.active.youtube.getPlayerState() == YT.PlayerState.CUED
      ) {

        this.active.youtube.playVideo();
      }
    } catch ( error ) {}
  }

  @HostListener( 'document:click', [ '$event' ] ) onClick( event: Event ) {

    // check banner
    if ( $( event.target ).parents( '#idx-bn' )[ 0 ] ) {

      event.preventDefault();

      return this.onBanner( event );
    }

    // check popup
    if ( $( event.target ).parents( '#idx-pp' )[ 0 ] ) {

      event.preventDefault();

      return this.onPopup( event );
    }
  }

  ngOnInit() {

    // get ad
    this.getAd();

    // get index
    this.getIndex();
  }

  ngOnCycle( element: any ) {

    // get banner
    var index = $( element ).data( 'index' );
    var banner = $( element ).parent();

    // get span
    var span = banner.next();
    var active = span.find( 'span' ).eq( index );

    // check height
    if ( banner.outerHeight() < 1 ) return;

    // get position
    var position = banner.outerHeight() - active.outerHeight();

    if ( this.active.banner ) $( this.active.banner ).removeClass( 'active ' );

    // set active
    this.active.banner = active;

    if ( this.active.banner ) $( this.active.banner ).addClass( 'active' );

    // set animation
    if ( position < 0 ) return;

    span.stop().animate( {

      top: position + 'px'
    }, 500 );
  }

  ngOnDestroy() {

    // carousel
    for ( let key in this.carousel ) {

      this.carousel[ key ] = false;
    }

    // timeout
    for ( let key in this.timeout ) {

      clearTimeout( this.timeout[ key ] );

      this.timeout[ key ] = null;
    }

    // event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }

    // youtube
    for ( let key in this.youtube ) {

      // check player
      if ( this.youtube[ key ] ) {

        this.youtube[ key ].destroy();
      }
    }
  }

  ngOnYouTube() {

    this.timeout.youtube = setTimeout( () => {

      try {

        // init
        var index = 0;
        this.youtube = {};

        // set youtube create
        $( '.youtube' ).each( ( idx: number, element: any ) => {

          if ( idx > 0 ) {

            return $( element ).parents( '.card' ).addClass( 'card-' + ( ++index ) );
          }

          // set active
          $( element ).parents( '.card' ).addClass( 'active' );

          // get key
          var key = $( element ).attr( 'id' );

          // init youtube
          this.ngOnYoutubeLoad( key );

          // init active
          this.active.youtube = this.youtube[ key ];

          // init loading
          this.loading = false;
        } )

      } catch ( error ) {}

    }, 500 );
  }

  ngOnYoutubeLoad( key: string, play: boolean = false ) {

    // check loading
    if ( this.loading ) return alert( this.translateService.instant( 'message.video' ) );

    // set loading
    this.loading = true;

    // get data
    var element = $( '#' + key );
    var youtube = element.data( 'youtube' );

    // get class
    var card = $( element ).parents( '.card' );

    for ( var name of card[ 0 ].classList ) {

      if ( name.indexOf( 'card-' ) > -1 ) {

        // set current
        card.removeClass( name ).addClass( 'active' );

        // set next
        if ( this.active.youtube ) {

          this.active.youtube.pauseVideo();

          $( this.active.youtube.a ).parents( '.card' ).removeClass( 'active' ).addClass( name );
        }

        break;
      }
    }

    // check youtube
    if ( this.youtube[ key ] ) {

      return this.youtube[ key ].playVideo();
    }

    // set youtube
    this.youtube[ key ] = new YT.Player( key, {

      events: {

        onReady: ( event: any ) => {

          if ( play ) {

            event.target.playVideo();
          }
        },
        onStateChange: ( event: any ) => {

          switch ( event.data ) {

            case YT.PlayerState.PAUSED: {
              break;
            }
            case YT.PlayerState.PLAYING: {

              // set loading
              this.loading = false;

              // check active
              if ( this.active.youtube == event.target ) return;

              // set active 
              this.active.youtube = event.target;

              // check mobile
              if ( this.navigationService.isMobile ) {

                // set scroll
                setTimeout( () => {

                  $( 'html' )[ 0 ].scrollTop = $( '#idx-thestartv' ).offset().top - 90;
                } )
              }

              break;
            }
          }
        }
      },
      videoId: youtube,
      playerVars: {
        rel: 0,
        loop: false,
        version: 3,
        showinfo: 0,
        controls: 0,
        playsinline: 0
      }
    } )
  }

  // set
  setCookie() {

    var expires = new Date();

    expires.setDate( expires.getDate() + 365 );
    
    document.cookie = `${ Config.cookie.close }=${ new DateTime().transform( new Date(), null, 'yyyy-MM-dd' ) }; path=/; expires=' + ${ expires.toUTCString() }`;
  }

  // get
  get getCookie() {

    var cookie = document.cookie.match( `(^|;) ?${ Config.cookie.close }=([^;]*)(;|$)` );

    return cookie && cookie[ 2 ] ? cookie[ 2 ] : null;
  }

  // http
  getAd() {

    this.httpService.getAd( new GetAdRequest() ).subscribe( response => {

      if ( response.code ) return;

      this.res.adInfo = response.adInfo;

    }, error => {

      this.httpService.setError();
    } );
  }

  setAd( adId: number ) {

    this.httpService.setAd( new SetAdRequest( adId ) ).subscribe( response => {

      // do noting
    }, error => {

      this.httpService.setError();
    } );
  }

  getIndex() {

    this.httpService.getIndex( new GetIndexRequest( this.getCookie ) ).subscribe( response => {

      if ( response.code ) alert( response.message );

      // set data
      this.res.pollInfos = response.pollInfos;
      this.res.newsInfos = response.newsInfos;
      this.res.videoInfos = response.videoInfos;
      this.res.boardInfos = response.boardInfos;
      this.res.popupInfos = response.popupInfos;
      this.res.bannerInfos = response.bannerInfos;

      // set carousel next
      if ( this.res.popupInfos.length > 1 ) {

        this.carousel.popup = true;

        this.onCarousel( false );
      }

      if ( this.res.bannerInfos.length > 1 ) {

        this.carousel.banner = true;

        this.onCarousel( true );
      }

      // set carousel data
      if ( this.res.popupInfos.length == Config.carousel ) this.res.popupInfos = this.res.popupInfos.concat( this.res.popupInfos );
      if ( this.res.bannerInfos.length == Config.carousel ) this.res.bannerInfos = this.res.bannerInfos.concat( this.res.bannerInfos );

      // set youtube
      this.res.videoInfos.forEach( ( videoInfo: BoardInfo ) => {

        videoInfo.dataInfo.mediaInfos.forEach( ( mediaInfo: EditorInfo ) => {

          if ( mediaInfo.content.indexOf( Config.youtube.embed ) > -1 ) {

            mediaInfo.content = mediaInfo.content.replace( Config.youtube.embed, '' );
            mediaInfo.caption = Config.youtube.image + mediaInfo.content + Config.youtube.quality[ 1 ];
          }
        } )
      } )

      // set poll
      this.res.pollInfos.forEach( ( pollInfo: BoardInfo ) => {

        /* set sort
        pollInfo.pollInfo.itemInfos.sort( ( a: ItemInfo, b: ItemInfo ) => {

          return b.percented - a.percented;
        } ) */

        pollInfo.pollInfo.itemInfos = pollInfo.pollInfo.itemInfos.splice( 0, 2 );
        pollInfo.dataInfo.mediaInfos = pollInfo.dataInfo.mediaInfos.splice( 0, 2 );
      } )

      // init youtube
      this.ngOnYouTube();

    }, error => {

      this.httpService.setError();
    } );
  }

  setPopup( popupId: number ) {

    this.httpService.setPopup( new SetPopupRequest( popupId ) ).subscribe( response => {

      // do noting
    }, error => {

      this.httpService.setError();
    } );
  }

  setBanner( bannerId: number ) {

    this.httpService.setBanner( new SetBannerRequest( bannerId ) ).subscribe( response => {

      // do noting
    }, error => {

      this.httpService.setError();
    } );
  }

  // action
  onAd() {

    this.setAd( this.res.adInfo.adId );

    if ( N.native() ) return N.callExternalBrowser( this.res.adInfo.link );

    return window.open( this.res.adInfo.link, '_blank' );
  }

  onPopup( event: Event ) {

    // get popup
    var popupInfo: PopupInfo = this.res.popupInfos[ $( event.target ).parents( '.carousel-item' ).data( 'index' ) ] || null;

    // set popup
    if ( popupInfo ) {

      this.setPopup( popupInfo.popupId );

      if ( N.native() ) return N.callExternalBrowser( popupInfo.link );

      return window.open( popupInfo.link, '_blank' );
    }
  }

  onClose( cookie: boolean ) {

    // set cookie
    if ( cookie ) this.setCookie();

    // clear timeout
    clearTimeout( this.timeout.popup );

    // remove timeout
    this.timeout.popup = null;
    this.carousel.popup = false;

    // set class
    $( '#idx-pp' ).removeClass( 'block' );
  }

  onBanner( event: Event ) {

    // get banner
    var bannerInfo: BannerInfo = this.res.bannerInfos[ $( event.target ).parents( '.carousel-item' ).data( 'index' ) ] || null;

    // set banner
    if ( bannerInfo ) {

      this.setBanner( bannerInfo.bannerId );

      return this.router.navigate( [ bannerInfo.link.replace( /http(.*?)\/\/(.*?)\//g, '' ) ] );

      /*
      if ( N.native() ) return N.callExternalBrowser( bannerInfo.link );

      return window.open( bannerInfo.link, '_blank' ); */
    }
  }

  onYoutube( event: Event ) {

    this.ngOnYoutubeLoad( $( event.target ).children( '.youtube' ).attr( 'id' ), true );
  }

  onCarousel( banner: boolean ) {

    if ( banner ) {

      this.timeout.banner = setTimeout( () => {

        if ( this.carousel.banner ) {

          // set next
          M.Carousel.getInstance( $( '#idx-bn .carousel' ) ).next();
        }

        this.onCarousel( true );

        return;

      }, 8000 );

    } else {

      this.timeout.popup = setTimeout( () => {

        if ( this.carousel.popup ) {

          // set next
          M.Carousel.getInstance( $( '#idx-pp .carousel' ) ).next();
        }

        this.onCarousel( false );

        return;

      }, 6000 );

    }
  }
}