import { Config } from 'src/app/app.config';
import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import 'rxjs/add/operator/map';

import {
	Result, 
	GetIndexRequest, GetIndexResponse,
	GetCategoriesRequest, GetCategoriesResponse,
	GetTranslatesRequest, GetTranslatesResponse,
	GetInRequest, GetInResponse,
	GetOutRequest, GetOutResponse,
	GetEmailRequest, GetEmailResponse,
	GetSocialRequest, GetSocialResponse,
	GetSessionRequest, GetSessionResponse,
	GetPasswordRequest, GetPasswordResponse,
	SetUpRequest, SetUpResponse,
	SetDropRequest, SetDropResponse,
	SetAuthRequest, SetAuthResponse,
	SetUserRequest, SetUserResponse,
	SetTokenRequest, SetTokenResponse,
	SetGoogleRequest, SetGoogleResponse,
	SetTwitterRequest, SetTwitterResponse,
	SetFacebookRequest, SetFacebookResponse,
	SetPasswordRequest, SetPasswordResponse,
	GetEulaRequest, GetEulaResponse,
	GetEulasRequest, GetEulasResponse,
	GetUserRequest, GetUserResponse,
	GetMarksRequest, GetMarksResponse,
	GetRepliesRequest, GetRepliesResponse,
	GetBoardsRequest, GetBoardsResponse,
	GetBoardRequest, GetBoardResponse,
	SetMarkRequest, SetMarkResponse,
	GetReplyListRequest, GetReplyListResponse,
	SetReplyRequest, SetReplyResponse,
	UpdReplyRequest, UpdReplyResponse,
	DelReplyRequest, DelReplyResponse,
	SetReportRequest, SetReportResponse,
	SetContactRequest, SetContactResponse,
	SetPollRequest, SetPollResponse,
	GetRouteRequest, GetRouteResponse,
	SetPopupRequest, SetPopupResponse,
	SetBannerRequest, SetBannerResponse,
	GetAdRequest, GetAdResponse,
	SetAdRequest, SetAdResponse
} from './http.classes';
import {
	PreloaderService
} from 'src/app/services/preloader/preloader.service';

@Injectable()
export class HttpService {

  languageType: string;
  translateType: number;

  constructor(
    private http : Http,
    private preloaderService: PreloaderService
  ) {

    this.languageType = Config.translate.base.code;
    this.translateType = Config.translate.base.type;
  }

  setError() {

    this.preloaderService.stop();

    alert( Result.getMessage( Result.CLIENT_FAILED_NETWORK, this.translateType ) );
  }

  setLanguage( language: string ) {

    this.languageType = language;
  }

  setTranslate( translate: number ) {

    this.translateType = translate;
  }

  
// 인덱스 정보
getIndex(req: GetIndexRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'closed', encodeURIComponent( req.closed || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/getIndex', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetIndexResponse( res.json() ); } );
}

// 분야 목록
getCategories(req: GetCategoriesRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/getCategories', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetCategoriesResponse( res.json() ); } );
}

// 번역 목록
getTranslates(req: GetTranslatesRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/getTranslates', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetTranslatesResponse( res.json() ); } );
}

// 로그인
getIn(req: GetInRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'email', encodeURIComponent( req.email || '' ) );
	params.set( 'password', encodeURIComponent( req.password || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/getIn', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetInResponse( res.json() ); } );
}

// 로그아웃
getOut(req: GetOutRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/getOut', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetOutResponse( res.json() ); } );
}

// 인증 요청
getEmail(req: GetEmailRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'email', encodeURIComponent( req.email || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/getEmail', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetEmailResponse( res.json() ); } );
}

// 소셜 확인
getSocial(req: GetSocialRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'email', encodeURIComponent( req.email || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/getSocial', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetSocialResponse( res.json() ); } );
}

// 세션
getSession(req: GetSessionRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/getSession', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetSessionResponse( res.json() ); } );
}

// 재설정 요청
getPassword(req: GetPasswordRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'email', encodeURIComponent( req.email || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/getPassword', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetPasswordResponse( res.json() ); } );
}

// 가입
setUp(req: SetUpRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'email', encodeURIComponent( req.email || '' ) );
	params.set( 'password', encodeURIComponent( req.password || '' ) );
	params.set( 'passwordCheck', encodeURIComponent( req.passwordCheck || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/setUp', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetUpResponse( res.json() ); } );
}

// 탈퇴
setDrop(req: SetDropRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'password', encodeURIComponent( req.password || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/setDrop', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetDropResponse( res.json() ); } );
}

// 인증
setAuth(req: SetAuthRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'auth', encodeURIComponent( req.auth || '' ) );
	params.set( 'email', encodeURIComponent( req.email || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/setAuth', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetAuthResponse( res.json() ); } );
}

// 수정
setUser(req: SetUserRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'password', encodeURIComponent( req.password || '' ) );
	params.set( 'passwordCheck', encodeURIComponent( req.passwordCheck || '' ) );
	params.set( 'passwordUpdate', encodeURIComponent( req.passwordUpdate || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/setUser', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetUserResponse( res.json() ); } );
}

// 토큰 등록
setToken(req: SetTokenRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'token', encodeURIComponent( req.token || '' ) );
	params.set( 'device', encodeURIComponent( req.device || '' ) );
	params.set( 'locale', encodeURIComponent( req.locale || '' ) );
	params.set( 'subscribe', req.subscribe ? req.subscribe.toString() : '0' );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/setToken', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetTokenResponse( res.json() ); } );
}

// 구글
setGoogle(req: SetGoogleRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'idToken', encodeURIComponent( req.idToken || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/setGoogle', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetGoogleResponse( res.json() ); } );
}

// 트위터
setTwitter(req: SetTwitterRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'idToken', encodeURIComponent( req.idToken || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/setTwitter', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetTwitterResponse( res.json() ); } );
}

// 페이스북
setFacebook(req: SetFacebookRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'idToken', encodeURIComponent( req.idToken || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/setFacebook', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetFacebookResponse( res.json() ); } );
}

// 재설정
setPassword(req: SetPasswordRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'auth', encodeURIComponent( req.auth || '' ) );
	params.set( 'email', encodeURIComponent( req.email || '' ) );
	params.set( 'password', encodeURIComponent( req.password || '' ) );
	params.set( 'passwordCheck', encodeURIComponent( req.passwordCheck || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/sign/setPassword', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetPasswordResponse( res.json() ); } );
}

// 약관 정보
getEula(req: GetEulaRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'eulaId', req.eulaId ? req.eulaId.toString() : '0' );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/getEula', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetEulaResponse( res.json() ); } );
}

// 약관 목록
getEulas(req: GetEulasRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/getEulas', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetEulasResponse( res.json() ); } );
}

// 사용자 정보
getUser(req: GetUserRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/getUser', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetUserResponse( res.json() ); } );
}

// 사용자 마크 목록
getMarks(req: GetMarksRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'index', req.index ? req.index.toString() : '0' );
	params.set( 'limit', req.limit ? req.limit.toString() : '0' );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/getMarks', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetMarksResponse( res.json() ); } );
}

// 사용자 댓글 목록
getReplies(req: GetRepliesRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'index', req.index ? req.index.toString() : '0' );
	params.set( 'limit', req.limit ? req.limit.toString() : '0' );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/getReplies', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetRepliesResponse( res.json() ); } );
}

// 게시물 목록
getBoards(req: GetBoardsRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'categoryType', req.categoryType ? req.categoryType.toString() : '0' );
	params.set( 'indexType', req.indexType ? req.indexType.toString() : '0' );
	params.set( 'index', req.index ? req.index.toString() : '0' );
	params.set( 'limit', req.limit ? req.limit.toString() : '0' );
	params.set( 'query', encodeURIComponent( req.query || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/getBoards', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetBoardsResponse( res.json() ); } );
}

// 게시물 정보
getBoard(req: GetBoardRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'boardId', req.boardId ? req.boardId.toString() : '0' );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/getBoard', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetBoardResponse( res.json() ); } );
}

// 마크 등록, 삭제
setMark(req: SetMarkRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'boardId', req.boardId ? req.boardId.toString() : '0' );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/setMark', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetMarkResponse( res.json() ); } );
}

// 댓글 목록
getReplyList(req: GetReplyListRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'boardId', req.boardId ? req.boardId.toString() : '0' );
	params.set( 'index', req.index ? req.index.toString() : '0' );
	params.set( 'limit', req.limit ? req.limit.toString() : '0' );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/getReplyList', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetReplyListResponse( res.json() ); } );
}

// 댓글 등록
setReply(req: SetReplyRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'boardId', req.boardId ? req.boardId.toString() : '0' );
	params.set( 'content', encodeURIComponent( req.content || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/setReply', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetReplyResponse( res.json() ); } );
}

// 댓글 수정
updReply(req: UpdReplyRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'replyId', req.replyId ? req.replyId.toString() : '0' );
	params.set( 'content', encodeURIComponent( req.content || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/updReply', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new UpdReplyResponse( res.json() ); } );
}

// 댓글 삭제
delReply(req: DelReplyRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'replyId', req.replyId ? req.replyId.toString() : '0' );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/delReply', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new DelReplyResponse( res.json() ); } );
}

// 댓글 신고
setReport(req: SetReportRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'replyId', req.replyId ? req.replyId.toString() : '0' );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/setReport', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetReportResponse( res.json() ); } );
}

// 문의 등록
setContact(req: SetContactRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'name', encodeURIComponent( req.name || '' ) );
	params.set( 'email', encodeURIComponent( req.email || '' ) );
	params.set( 'phone', encodeURIComponent( req.phone || '' ) );
	params.set( 'content', encodeURIComponent( req.content || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/contact/setContact', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetContactResponse( res.json() ); } );
}

// 여론조사 등록
setPoll(req: SetPollRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'pollId', req.pollId ? req.pollId.toString() : '0' );
	params.set( 'itemId', req.itemId ? req.itemId.toString() : '0' );
	params.set( 'boardId', req.boardId ? req.boardId.toString() : '0' );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/setPoll', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetPollResponse( res.json() ); } );
}

// 라우터 정보
getRoute(req: GetRouteRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'device', encodeURIComponent( req.device || '' ) );
	params.set( 'version', encodeURIComponent( req.version || '' ) );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/route/getRoute', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetRouteResponse( res.json() ); } );
}

// 팝업 로그
setPopup(req: SetPopupRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'popupId', req.popupId ? req.popupId.toString() : '0' );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/setPopup', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetPopupResponse( res.json() ); } );
}

// 배너 로그
setBanner(req: SetBannerRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'bannerId', req.bannerId ? req.bannerId.toString() : '0' );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/setBanner', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetBannerResponse( res.json() ); } );
}

// 광고 정보
getAd(req: GetAdRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/getAd', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new GetAdResponse( res.json() ); } );
}

// 광고 로그
setAd(req: SetAdRequest) {
	
	this.preloaderService.start();
	
	let params: URLSearchParams = new URLSearchParams();
	params.set( 'adId', req.adId ? req.adId.toString() : '0' );
	var headers = new Headers( {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Language-Type': this.languageType, 
		'Translate-Type': this.translateType
	} );
	
	return this.http.post( '/api/setAd', params.toString(), { headers: headers })
		.map( res => { this.preloaderService.stop(); return new SetAdResponse( res.json() ); } );
}

}
