import * as sticky from 'stickyfilljs';
import {
  Directive,
  ElementRef
} from '@angular/core';

@Directive( {
  selector: '[stickyInit]'
} )
export class StickyInit {

  constructor( private elementRef: ElementRef ) {}

  ngAfterViewInit() {

    sticky.addOne( this.elementRef.nativeElement );
  }
}