import {
  Component,
  OnInit
} from '@angular/core';
import {
  SetContactRequest
} from 'src/app/services/http/http.classes';
import {
  NgForm
} from '@angular/forms';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: [ './contact.component.css' ]
} )
export class ContactComponent implements OnInit {

  public req: any

  constructor(
    private httpService: HttpService,
    private translateService: TranslateService
  ) {

    this.req = {

      setContact: new SetContactRequest()
    }
  }

  ngOnInit() {}

  // get 
  get getMaximum() {

    return Config.text;
  }

  // http
  setContact( form: NgForm, input: any ) {

    // check form
    if ( form.invalid ) return alert( this.translateService.instant( 'message.invalid.form' ) );

    // check lenth
    if ( input.value.length > Config.text ) return alert( this.translateService.instant( 'message.invalid.maximum', {

      number: Config.text
    } ) );

    // request
    this.httpService.setContact( this.req.setContact ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      alert( this.translateService.instant( 'message.contact' ) );

      // init
      this.req.setContact = new SetContactRequest();

      // interface
      setTimeout( () => {

        var textarea = $( 'textarea' )

        // set resize
        M.textareaAutoResize( textarea );

        // set counter
        $( textarea ).characterCounter();
      } );

    }, error => {

      this.httpService.setError();
    } );
  }

  // action
  onContact( form: NgForm, input: any ) {

    // check valid
    if ( form.invalid ) return true;

    // check length
    if ( input.value ) {

      return input.value.length < 1 || input.value.length > Config.text;
    }

    return true;
  }
}