import {
  NgModule
} from '@angular/core';
import {
  HttpModule
} from '@angular/http';
import {
  AngularFireModule
} from '@angular/fire';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  Routes,
  RouterModule
} from '@angular/router';
import {
  AngularFireAuthModule
} from '@angular/fire/auth';
import {
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader
} from '@ngx-translate/core';
import {
  BrowserModule
} from '@angular/platform-browser'
import {
  TranslateHttpLoader
} from '@ngx-translate/http-loader'; // 언어팩 모듈 ( /web/client.real/src/assets/i18n/* )

// config
import {
  Config
} from './app.config'; // 설정 파일

// root
import {
  MainComponent
} from './components/main/main.component'; // 메인 화면 구성

// public
import {
  ShareComponent
} from './components/share/share.component'; // 공유 기능 ( 게시물 상세 )
import {
  FooterComponent
} from './components/footer/footer.component'; // 메인 화면 하단
import {
  NavigationComponent
} from './components/navigation/navigation.component'; // 메인 화면 상단
import {
  PaginationComponent
} from './components/pagination/pagination.component'; // 페이지 조회 기능 ( ex: 게시물 목록 하단 페이지 )

// directive
import {
  ReadyInit
} from './directive/ready.directive'; // 뷰 초기로딩시 이벤트 발생 ( 사용하지 않음 )
import {
  StickyInit
} from './directive/sticky.directive'; // PC 화면 스크롤시 따라다니는 메뉴 ( https://www.npmjs.com/package/sticky-js )
import {
  PaginationInit
} from './directive/pagination.directive'; // 페이지 조회 디렉티브
import {
  MaterializeInit
} from './directive/materialize.directive'; // Materializecss 디렉티브 ( web/client.real/angular.json - https://materializecss.com )

// guard
import {
  SessionGuard
} from './guard/session.guard'; // 세션 확인
import {
  TranslateGuard
} from './guard/translate.guard'; // 데이터베이스 언어 조회 및 언어팩 적용, 메인 화면 상단 메뉴 정보 조회

// service
import {
  HttpService
} from './services/http/http.service'; // API 통신
import {
  TranslatesService
} from './services/translates/translates.service'; // 언어팩 이벤트 처리
import {
  PaginationService
} from './services/pagination/pagination.service'; // 페이지 조회 이벤트 처리
import {
  NavigationService
} from './services/navigation/navigation.service'; // 메인 화면 상단 이벤트 처리

// pipe
import {
  Src,
  Url,
  Open,
  Html,
  Join,
  Blank,
  DateTime,
  Transparent
} from './pipe/pipe';

// page
import {
  IndexComponent
} from './components/page/index/index.component'; // 홈
import {
  ErrorComponent
} from './components/page/error/error.component'; // 에러 화면 ( SNS 로그인 실패, 이메일 인증 실패 )
import {
  EulaComponent
} from './components/page/eula/eula.component'; // Android, iOS 약관 페이지
import {
  ContactComponent
} from './components/page/contact/contact.component'; // 메인 화면 하단 → Contact
import {
  SignInComponent
} from './components/page/sign-in/sign-in.component'; // 로그인 ( LOGIN )
import {
  SignUpComponent
} from './components/page/sign-up/sign-up.component'; // 회원가입
import {
  SignResetComponent
} from './components/page/sign-reset/sign-reset.component'; // 비밀번호 재설정 ( RESET PASSWORD )
import {
  SignConfirmComponent
} from './components/page/sign-confirm/sign-confirm.component'; // 회원가입 인증 ( 인증 이메일 링크 이동 )
import {
  SignPasswordComponent
} from './components/page/sign-password/sign-password.component'; // 비밀번호 변경 ( 비밀번호 재설정 이메일 링크 이동 )
import {
  UserLikesComponent
} from './components/page/user-likes/user-likes.component'; // 마이페이지 ( MYPAGE ) → 좋아요 목록 ( LIKES )
import {
  UserRepliesComponent
} from './components/page/user-replies/user-replies.component'; // 마이페이지 ( MYPAGE ) → 나의 댓글 ( REPLIES )
import {
  UserInfomationComponent
} from './components/page/user-infomation/user-infomation.component'; // 마이페이지 ( MYPAGE ) → 회원 정보 ( INFOMATION )
import {
  BoardDetailComponent
} from './components/page/board-detail/board-detail.component'; // 게시물 상세
import {
  BoardCategoryComponent
} from './components/page/board-category/board-category.component'; // 게시물 목록 ( STAR, FASION, BEAUTY ... )
import {
  NoticeListComponent
} from './components/page/notice-list/notice-list.component'; // 메인 화면 하단 → Notice
import {
  NoticeDetailComponent
} from './components/page/notice-detail/notice-detail.component'; // 공지사항 상세

// set route
const routes: Routes = [ {

  path: Config.base.main.path,
  component: MainComponent,
  canActivate: [ TranslateGuard ],
  children: [ {

      path: Config.base.main.path,
      component: IndexComponent
    },
    {
      path: Config.page.error.path,
      component: ErrorComponent,
    },
    {
      path: Config.page.eula.path,
      component: EulaComponent
    },
    {
      path: Config.page.contact.path,
      component: ContactComponent
    },
    {
      path: Config.page.sign.in.path,
      component: SignInComponent
    },
    {
      path: Config.page.sign.up.path,
      component: SignUpComponent
    },
    {
      path: Config.page.sign.reset.path,
      component: SignResetComponent
    },
    {
      path: Config.page.sign.confirm.path,
      component: SignConfirmComponent
    },
    {
      path: Config.page.sign.password.path,
      component: SignPasswordComponent
    },
    {
      path: Config.page.user.likes.path,
      component: UserLikesComponent,
      canActivate: [ SessionGuard ]
    },
    {
      path: Config.page.user.replies.path,
      component: UserRepliesComponent,
      canActivate: [ SessionGuard ]
    },
    {
      path: Config.page.user.infomation.path,
      component: UserInfomationComponent,
      canActivate: [ SessionGuard ]
    },
    {
      path: Config.page.notice.list.path,
      component: NoticeListComponent
    },
    {
      path: Config.page.notice.detail.path,
      component: NoticeDetailComponent
    },
    {
      path: Config.page.board.detail.path,
      component: BoardDetailComponent
    },
    {
      path: Config.page.board.category.path,
      component: BoardCategoryComponent
    }
  ],
} ];

// export translation
export function createTranslateLoader( client: HttpClient ) {

  return new TranslateHttpLoader( client, Config.translate.path, Config.translate.type );
}

@NgModule( {
  declarations: [
    Src, Url, Open, Html, Join, Blank, DateTime, Transparent, // pipe
    ReadyInit, StickyInit, PaginationInit, MaterializeInit, // directive
    ShareComponent, FooterComponent, NavigationComponent, PaginationComponent, // public
    MainComponent, IndexComponent, ErrorComponent, EulaComponent, ContactComponent, // root
    SignInComponent, SignUpComponent, SignResetComponent, SignConfirmComponent, SignPasswordComponent, // sign
    UserLikesComponent, UserRepliesComponent, UserInfomationComponent, // user
    NoticeListComponent, NoticeDetailComponent, // notice
    BoardCategoryComponent, BoardDetailComponent, // board
  ],
  providers: [
    HttpService,
    SessionGuard,
    TranslateGuard,
    TranslatesService,
    PaginationService,
    NavigationService,
  ],
  imports: [
    HttpModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AngularFireModule.initializeApp( Config.firebase ),
    ReactiveFormsModule,
    AngularFireAuthModule,
    RouterModule.forRoot( routes, {

      onSameUrlNavigation: 'reload' // 동일한 경로 이벤트 처리
    } ),
    TranslateModule.forRoot( {
      loader: {
        deps: [ HttpClient ],
        provide: TranslateLoader,
        useFactory: ( createTranslateLoader )
      }
    } )
  ],
  exports: [
    RouterModule,
    TranslateModule
  ]
} )
export class AppRoutingModule {}