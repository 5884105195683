import {
  EventEmitter
} from '@angular/core';

// class
export class Pager {

  page: number = 1;
  click: EventEmitter < number > ;
  parent: Element;
  maximum: number = 1;

  constructor( {

    page,
    click,
    parent,
    maximum,
  }: {

    page ? : number,
    click ? : EventEmitter < number > ,
    parent ? : Element,
    maximum ? : number,
  } ) {

    if ( page ) this.page = page;
    if ( click ) this.click = click;
    if ( parent ) this.parent = parent;
    if ( maximum ) this.maximum = maximum;
  }
}

export class PagerData {

  page: number;
  maximum: number;

  constructor( maximum: number = 1, page: number = 1 ) {

    this.page = page;
    this.maximum = maximum;
  }
}

export class PaginationService {

  public push: any;
  public maximum: number;

  constructor() {

    this.push = {

      page: new EventEmitter()
    }
  }

  init( data: Pager ) {

    if ( data.page == null ) data.page = 1;
    if ( data.maximum == null ) data.maximum = 1;

    // event
    this.push.page.emit( data );
  }
}