import {
  OnInit,
  Component,
  ElementRef
} from '@angular/core';
import {
  Subscription
} from 'rxjs';
import {
  Config
} from 'src/app/app.config';
import {
  Pager,
  PaginationService,
} from 'src/app/services/pagination/pagination.service';

@Component( {
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: [ './pagination.component.css' ]
} )
export class PaginationComponent implements OnInit {

  // private
  public min: number;
  public max: number;
  public pager: Pager;
  public pages: Array < number > ;
  public subscription: Subscription;

  constructor(
    private element: ElementRef,
    private paginationService: PaginationService
  ) {

    // init
    this.pager = new Pager( {} );
  }

  ngOnInit() {

    // subscript
    this.subscription = this.paginationService.push.page.subscribe( ( data: Pager ) => {

      // check same element
      if ( this.element.nativeElement == data.parent ) {

        // set data
        this.pager = data;
        this.pages = new Array();

        // set min, max
        this.min = Math.floor( ( this.pager.page - 1 ) / Config.range ) * Config.range + 1;
        this.max = Math.min( this.min + Config.range, Math.ceil( this.pager.maximum ) + 1 );

        // set pages
        for ( var idx = this.min; idx < this.max; idx++ ) {

          this.pages.push( idx );
        }
      }
    } );
  }

  ngOnDestory() {

    this.subscription.unsubscribe();
  }

  // get 
  get getPrev() {
    
    return this.min / Config.range < 1;
  }

  get getNext() {

    return this.max > this.pager.maximum;
  }

  // action
  onPrev() {

    this.setPage( this.min - 1 );
  }

  onNext() {

    this.setPage( this.max );
  }

  setPage( page: number ) {

    this.pager.click.emit( page );
  }
}