import {
  Component,
  OnInit
} from '@angular/core';
import {
  PreloaderService
} from 'src/app/services/preloader/preloader.service'

@Component( {
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: [ './preloader.component.css' ]
} )

export class PreloaderComponent implements OnInit {

  active: boolean = false;

  constructor( preloader: PreloaderService ) {

    preloader.status.subscribe( ( status: boolean ) => {

      this.active = status;
    } );
  }

  ngOnInit() {}
}
