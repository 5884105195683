import {
  Injectable,
  EventEmitter,
} from '@angular/core';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  Result,
  CategoryInfo,
  GetSessionRequest,
  GetCategoriesRequest,
} from 'src/app/services/http/http.classes';

@Injectable()
export class NavigationService {

  // init
  private res: any;
  private user: number;
  private index: number;
  private session: boolean;

  // event
  public subscription: any;

  constructor(
    private httpService: HttpService,
    private translateService: TranslateService
  ) {

    this.res = {

      sessionInfo: new CategoryInfo(),
      categoryInfos: new Array < CategoryInfo > ()
    }

    // init
    this.index = 0;
    this.session = false;

    // event
    this.subscription = {

      loaded: new EventEmitter()
    }
  }

  // check
  isSession( user ? : number ) {

    if ( user ) {

      return this.session && this.user == user;
    }

    return this.session
  }

  get isMobile() {

    return /iphone|ipad|ipod|android/i.test( navigator.userAgent.toLowerCase() );
  }

  // get
  getCategory( name: string ) {

    var category = this.res.categoryInfos.concat( this.res.sessionInfo ).find( ( categoryInfo: CategoryInfo ) => {

      return categoryInfo.manager.indexOf( name ) > -1;
    } );

    return category ? category : null;
  }

  get getRoute() {

    var category = this.res.categoryInfos.concat( this.res.sessionInfo ).find( ( categoryInfo: CategoryInfo ) => {

      return categoryInfo.categoryType == this.index;
    } );

    if ( category ) {

      return [ category.path ];
    }

    return [ Config.base.index.path ];
  }

  get getIndex() {

    return this.index;
  }

  get getCategoryList(): Array < CategoryInfo > {

    return this.res.categoryInfos.concat( this.res.sessionInfo );
  }

  // set
  setIndex( index: number = 0 ) {

    this.index = index;
  }

  // http
  getSession( url: string = null ) {

    // get session
    this.httpService.getSession( new GetSessionRequest() ).subscribe( ( response ) => {

      this.user = response.user;
      this.session = response.code == Result.CLIENT_SUCCESS;

    }, error => {

      this.user = null
      this.session = false;

      this.httpService.setError();

    }, () => {

      // get session
      var name = this.translateService.instant( this.session ? 'menu.mypage' : 'menu.login' );
      var path = this.session ? Config.page.user.infomation.path : Config.page.sign.in.path;
      var index = 99;
      var manager = 'user';

      // set session
      this.res.sessionInfo = new CategoryInfo( {

        name: name,
        path: path,
        sorted: index,
        manager: manager,
        categoryType: index
      } );

      // set event
      this.subscription.loaded.emit( url );
    } );
  }

  getCategories() {

    return new Promise( ( resolve ) => {

      // check category
      if ( this.res.categoryInfos > 1 ) {

        return resolve();
      }

      // get category
      this.httpService.getCategories( new GetCategoriesRequest() ).subscribe( response => {

        if ( response.code ) {

          alert( response.message );

          return resolve();
        }

        // set data
        this.res.categoryInfos = response.categoryInfos;

        // resolve
        return resolve();
      }, error => {

        this.httpService.setError();
      } );
    } );
  }
}