import {
  Input,
  Output,
  Directive,
  OnChanges,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import {
  Pager,
  PagerData,
  PaginationService,
} from '../services/pagination/pagination.service';

@Directive( {
  selector: '[paginationInit], [paginationOption.click]'
} )
export class PaginationInit implements OnChanges {

  private parent: Element;

  @Input( 'paginationInit' ) data: PagerData = new PagerData();
  @Output( 'paginationOption.click' ) click: EventEmitter < number > = new EventEmitter < number > ();

  constructor(
    private elementRef: ElementRef,
    private paginationService: PaginationService
  ) {

    // set element
    this.parent = this.elementRef.nativeElement;
  }

  ngOnChanges() {

    this.paginationService.init( new Pager( {

      page: this.data.page,
      click: this.click,
      parent: this.parent,
      maximum: this.data.maximum
    } ) );
  }
}