import {
  OnInit,
  Component,
} from '@angular/core';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  EulaInfo,
  GetEulaRequest,
} from 'src/app/services/http/http.classes';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

declare var $: any;
declare var N: any;
declare var M: any;

@Component( {
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.css' ]
} )
export class FooterComponent implements OnInit {

  // data
  res: any;

  constructor(
    private httpService: HttpService,
    private translateService: TranslateService,
    private navigationService: NavigationService
  ) {

    // init
    this.res = {

      eulaInfo: new EulaInfo()
    }
  }

  ngOnInit() {}

  // get 
  get getSocial() {

    var social = this.translateService.instant( 'social' );

    if ( social instanceof Array ) {

      return social;
    }

    return [];
  }

  get getCompany() {

    var company = this.translateService.instant( 'footer.company' );

    if ( company instanceof Array ) {

      return company;
    }

    return [];
  }

  // http
  getEula( eulaId: number ) {

    // get eulas
    this.httpService.getEula( new GetEulaRequest( eulaId ) ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.eulaInfo = response.eulaInfo;

      // open modal
      M.Modal.getInstance( $( '#modal-eula' ) ).open();
    }, error => {

      this.httpService.setError();
    } );
  }

  // action
  onSocial( url: string ) {

    if ( N.native() ) return N.callExternalBrowser( url );

    return window.open( url, '_blank' );
  }
}