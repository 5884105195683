import {
  Component,
  OnInit
} from '@angular/core';
import {
  NgForm
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  GetPasswordRequest
} from 'src/app/services/http/http.classes';

@Component( {
  selector: 'app-sign-reset',
  templateUrl: './sign-reset.component.html',
  styleUrls: [ './sign-reset.component.css' ]
} )
export class SignResetComponent implements OnInit {

  public req: any;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private translateService: TranslateService
  ) {

    // init
    this.req = {

      getPassword: new GetPasswordRequest()
    }
  }

  ngOnInit() {}

  // http
  setSubmit( form: NgForm ) {

    // check form
    if ( form.invalid ) return alert( this.translateService.instant( 'message.invalid.form' ) );

    // request
    this.httpService.getPassword( this.req.getPassword ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // success
      alert( this.translateService.instant( 'message.sign' ) );

      return this.router.navigate( [ Config.page.sign.in.path ] );

    }, error => {

      this.httpService.setError();
    } )
  }
}