import {
  Injectable,
  EventEmitter
} from '@angular/core';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  TranslateInfo,
  GetTranslatesRequest
} from 'src/app/services/http/http.classes';

@Injectable()
export class TranslatesService {

  // init
  private res: any;
  private cookie: string = 'ca_translate';

  // event
  public subscription: any;

  constructor(
    private httpService: HttpService,
    private translateService: TranslateService
  ) {

    // init
    this.res = {

      translateInfo: new TranslateInfo(),
      translateInfos: new Array < TranslateInfo > ()
    }

    this.subscription = {

      change: new EventEmitter(),
      loaded: new EventEmitter()
    }
  }

  // get
  getCookie( key: string ) {

    return document.cookie.match( '(^|;) ?' + key + '=([^;]*)(;|$)' ) || null;
  }

  get getTranslate() {

    return this.res.translateInfo;
  }

  get getTranslateList() {

    return this.res.translateInfos;
  }

  // set
  setCookie( key: string, value: string ) {

    document.cookie = `${ key }=${ value }; path=/`;
  }

  setTranslate( translateInfo: TranslateInfo = null ) {

    // check translate
    if ( translateInfo ) {

      // set translate
      this.res.translateInfo = translateInfo;

      // set cookie
      this.setCookie( this.cookie, this.res.translateInfo.code );
    } else {

      // get cookie code
      var lang = this.translateService.getBrowserLang().toLowerCase();
      var code = this.getCookie( this.cookie ) || lang;

      // get translate
      this.res.translateInfo = this.res.translateInfos.find( ( translateInfo: TranslateInfo ) => {

        if ( code.indexOf( translateInfo.code ) > -1 ) {

          return true;
        }
      } ) || new TranslateInfo( {

        code: Config.translate.base.code,
        manager: Config.translate.base.name,
        translateType: Config.translate.base.type
      } );

      // set http language
      this.httpService.setLanguage( lang );
    }

    // set http translate
    this.httpService.setTranslate( this.res.translateInfo.translateType );

    // set translate
    this.translateService.use( this.res.translateInfo.code );
    this.translateService.setDefaultLang( this.res.translateInfo.code );

    // set event
    this.subscription.change.emit();
  }

  // http
  getTranslates() {

    return new Promise( ( resolve ) => {

      // check translate
      if ( this.getTranslateList.length > 0 ) {

        return resolve();
      }

      // get translate
      this.httpService.getTranslates( new GetTranslatesRequest() ).subscribe( response => {

        if ( response.code ) {

          alert( response.message );

          return resolve();
        }

        this.res.translateInfos = response.translateInfos;

        // set translate
        this.setTranslate();

        // resolve
        return resolve();
      }, error => {

        this.httpService.setError();
      } );
    } );
  }
}