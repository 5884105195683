import {
  OnInit,
  Component,
  ElementRef
} from '@angular/core';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';
import {
  NavigationService
} from 'src/app/services/navigation/navigation.service';

declare var $: any;
declare var N: any;
declare var Kakao: any;

@Component( {
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: [ './share.component.css' ]
} )
export class ShareComponent implements OnInit {

  private href: string;
  private title: string;
  private width: number;
  private height: number;
  private parent: HTMLElement;
  private attribute: string;

  constructor(
    private element: ElementRef,
    private translateService: TranslateService,
    private navigationService: NavigationService
  ) {

    // init
    this.width = 480;
    this.height = 860;
    this.parent = element.nativeElement;
  }

  ngOnInit() {

    // set kakao
    this.ngOnKakao();

    // set attribute
    this.attribute = this.isMobile ? null : `width=${ this.width },height=${ this.height },top=${ ( screen.height - this.height ) / 2 },left=${ ( screen.width - this.width ) / 2 }`;
  }

  ngOnKakao() {

    if (
      Kakao.Link == null ||
      Kakao.Link == undefined
    ) {

      Kakao.init( Config.kakao.key );
    }
  }

  ngOnDestroy() {

    // destroy kakao
    if ( Kakao.Link ) Kakao.cleanup();
  }

  ngAfterViewChecked() {

    // check title
    if ( this.title ) return;

    // set attribute
    var title = $( this.parent ).data( 'title' );

    if ( title ) {

      // set timeout
      setTimeout( () => {

        this.href = encodeURIComponent( window.location.href );
        this.title = encodeURIComponent( title.replace( '%', '%25' ) );
      } );
    }
  }

  // check
  get isMobile() {

    return /iphone|ipad|ipod|android/i.test( navigator.userAgent.toLowerCase() );
  }

  // get
  get getPath() {

    return window.location.pathname.substr( 1, window.location.pathname.length ) + window.location.search;
  }

  get getLink() {

    return decodeURIComponent( this.href );
  }

  get getTitle() {

    return decodeURIComponent( this.title );
  }

  // action
  onLink( input: any ) {

    if ( N.native() ) return N.copyClipboard( this.getLink );

    $( input ).css( 'display', 'block' );

    try {

      input.select();

      document.execCommand( 'copy' );

      input.setSelectionRange( 0, 0 );

      alert( this.translateService.instant( 'message.copy.success' ) );

    } catch ( error ) {

      alert( this.translateService.instant( 'message.copy.fail' ) );
    }

    $( input ).css( 'display', 'none' );
    $( input ).blur();
  }

  onKakao() {

    try {

      Kakao.Link.sendCustom( {

        templateId: Config.kakao.template,
        installTalk: true,
        templateArgs: {

          QUERY: this.getPath,
          TITLE: this.getTitle,
          BUTTON: this.translateService.instant( 'caption.button.share' )
        }
      } )
    } catch ( error ) {}
  }

  onTwitter() {

    var url = `http://www.twitter.com/intent/tweet?text=${ this.title }&url=${ this.href }`;

    if ( N.native() ) return N.callExternalBrowser( url );

    return window.open( url, '_blank', this.attribute );
  }

  onFacebook() {

    var url = `http://www.facebook.com/sharer/sharer.php?u=${ this.href }`;

    if ( N.native() ) return N.callExternalBrowser( url );

    return window.open( url, '_blank', this.attribute );
  }

  onLine() {

    var url = `http://line.me/R/msg/text/?${ this.title }${ encodeURIComponent( '\n' ) }${ this.href }`;

    if ( N.native() ) return N.callExternalBrowser( url );

    return window.open( url, '_blank', this.attribute );
  }

  onBand() {

    var url = `http://band.us/plugin/share?body=${ this.title }${ encodeURIComponent( '\n' ) }${ this.href }&route=${ this.href }`;

    if ( N.native() ) return N.callExternalBrowser( url );

    return window.open( url, '_blank', this.attribute );
  }

  onBlog() {

    var url = `http://blog.naver.com/openapi/share?url=${ this.href }&title=${ this.title }`;

    if ( N.native() ) return N.callExternalBrowser( url );

    return window.open( url, '_blank', this.attribute );
  }
}