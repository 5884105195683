import {
  Component,
  OnInit
} from '@angular/core';
import {
  NgForm
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  EulaInfo,
  SetUpRequest,
  GetEulasRequest
} from 'src/app/services/http/http.classes';
import {
  TranslatesService
} from 'src/app/services/translates/translates.service';
declare var $: any;

@Component( {
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: [ './sign-up.component.css' ]
} )
export class SignUpComponent implements OnInit {

  public req: any;
  public res: any;
  public subscription: any;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private translateService: TranslateService,
    private translatesService: TranslatesService
  ) {

    this.req = {

      setUp: new SetUpRequest()
    }

    this.res = {

      eulaInfos: new Array < EulaInfo > ()
    }

    // event
    this.subscription = {

      translate: this.translatesService.subscription.change.subscribe( () => {

        // get eulas
        this.getEulas();
      } )
    }
  }

  ngOnInit() {

    // get eulas
    this.getEulas();
  }


  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  // check
  isInvalid( form: NgForm, count: number = 0 ) {

    for ( let key in form.value ) {

      count += form.value[ key ] ? 1 : 0;
    }

    return count < this.res.eulaInfos.length;
  }

  // http
  getEulas() {

    this.httpService.getEulas( new GetEulasRequest() ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.eulaInfos = response.eulaInfos;

    }, error => {

      this.httpService.setError();
    } )
  }

  setSubmit( form: NgForm, agree: boolean ) {

    // check agree
    if ( agree == false ) return alert( this.translateService.instant( 'message.invalid.agree' ) );

    // check form
    if ( form.invalid ) return alert( this.translateService.instant( 'message.invalid.form' ) );

    // request
    this.httpService.setUp( this.req.setUp ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // success
      alert( this.translateService.instant( 'message.sign' ) );

      return this.router.navigate( [ Config.page.sign.in.path ] );

    }, error => {

      this.httpService.setError();
    } )
  }

  // action
  onFocus( element: HTMLElement ) {

    // set remove
    $( '.container.active' ).removeClass( 'active' );

    // set active
    $( element ).addClass( 'active' );

    // scroll top
    $( 'html' ).stop().animate( {

      scrollTop: 0
    }, 300 )
  }

  onSubmit( form: NgForm, agree: boolean ) {

    // check agree
    if ( agree == false ) return;

    // check form
    if ( form.invalid ) return;

    // set submit
    this.setSubmit( form, agree );
  }
}