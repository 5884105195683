import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  TranslatesService
} from 'src/app/services/translates/translates.service';
import {
  ReplyInfo,
  GetRepliesRequest
} from 'src/app/services/http/http.classes';
import {
  PagerData
} from 'src/app/services/pagination/pagination.service';

@Component( {
  selector: 'app-user-replies',
  templateUrl: './user-replies.component.html',
  styleUrls: [ './user-replies.component.css' ]
} )
export class UserRepliesComponent implements OnInit {

  public req: any;
  public res: any;
  public page: any;
  public subscription: any;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private translatesService: TranslatesService,
  ) {

    // init
    this.req = {

      getReplies: new GetRepliesRequest()
    }

    this.res = {

      replyInfos: new Array < ReplyInfo > ()
    }

    this.page = {

      content: new PagerData()
    }

    // event
    this.subscription = {

      translate: this.translatesService.subscription.change.subscribe( () => {

        // get marks
        this.getReplies();
      } )
    }
  }

  ngOnInit() {

    // get marks
    this.getReplies();
  }

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  // check
  isIndex( replyInfo: ReplyInfo ) {

    return replyInfo.deleted || replyInfo.openType < 1;
  }

  // http
  getReplies( index: number = 1, limit: number = Config.limit.default ) {

    // set index
    this.req.getReplies.onInit( {

      index: limit * ( index - 1 ),
      limit: limit
    } );

    // request
    this.httpService.getReplies( this.req.getReplies ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.replyInfos = response.replyInfos;

      // set page
      this.page.content = new PagerData( response.pageInfo.maximum, index );
      
    }, error => {

      this.httpService.setError();
    } );
  }

  // action
  onBoard( replyInfo: ReplyInfo ) {

    return this.router.navigate( [ replyInfo.path ] );
  }
}