import {
  auth
} from 'firebase/app';
import {
  OnInit,
  Component
} from '@angular/core';
import {
  NgForm
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  AngularFireAuth
} from '@angular/fire/auth';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  Result,
  GetInRequest,
  GetEmailRequest,
  SetGoogleRequest,
  GetSocialRequest,
  SetTwitterRequest,
  SetFacebookRequest,
} from 'src/app/services/http/http.classes';
import {
  NavigationService
} from 'src/app/services/navigation/navigation.service';

declare var N: any;

@Component( {
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: [ './sign-in.component.css' ]
} )
export class SignInComponent implements OnInit {

  public req: any;

  constructor(
    private router: Router,
    private firebase: AngularFireAuth,
    private httpService: HttpService,
    private translateService: TranslateService,
    private navigationService: NavigationService
  ) {

    // init
    this.req = {

      getIn: new GetInRequest()
    }
  }

  ngOnInit() {}

  // http
  getEmail( message: string ) {

    // check message
    if ( false == confirm( this.translateService.instant( 'message.confirm.email', {

        message: message
      } ) ) ) {

      return;
    }

    // set email
    this.httpService.getEmail( new GetEmailRequest( this.req.getIn.email ) ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      return alert( this.translateService.instant( 'message.email' ) );
    }, error => {

      this.httpService.setError();
    } );
  }

  getSocial( email: string ) {

    this.httpService.getSocial( new GetSocialRequest( email ) ).subscribe( response => {

      return alert( response.message );

    }, error => {

      this.httpService.setError();
    } );
  }

  setSubmit( form: NgForm ) {

    // check valid
    if ( form.invalid ) return alert( this.translateService.instant( 'message.invalid.form' ) )

    // get sign in
    this.httpService.getIn( this.req.getIn ).subscribe( response => {

      // check code
      switch ( response.code ) {

        case Result.CLIENT_SUCCESS: {

          // get session
          this.navigationService.getSession( Config.base.index.path );

          // check native
          if ( N.native() ) return N.saveCookie();

          // router link
          return this.router.navigate( [ Config.base.index.path ] );
        }

        case Result.CLIENT_ERROR_USER_AUTH: {

          // get email
          return this.getEmail( response.message );
        }

        default: {

          return alert( response.message );
        }
      }

    }, error => {

      this.httpService.setError();
    } )
  }

  setGoogle( token: string ) {

    // request
    this.httpService.setGoogle( new SetGoogleRequest( token ) ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      return window.location.href = Config.base.index.path;

    }, ( error ) => {

      this.httpService.setError();
    } );
  }

  setTwitter( token: string ) {

    // request
    this.httpService.setTwitter( new SetTwitterRequest( token ) ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      return window.location.href = Config.base.index.path;

    }, ( error ) => {

      this.httpService.setError();
    } );
  }

  setFacebook( token: string ) {

    // request
    this.httpService.setFacebook( new SetFacebookRequest( token ) ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      return window.location.href = Config.base.index.path;

    }, ( error ) => {

      this.httpService.setError();
    } );
  }

  // action
  onGoogle() {

    // check native
    if ( N.native() ) return N.loginGoogle();

    // get google
    this.firebase.auth.signInWithPopup( new auth.GoogleAuthProvider() ).then( ( user: auth.UserCredential ) => {

      // get token
      user.user.getIdToken().then( ( token: string ) => {

        // set google
        this.setGoogle( token );
      } );

    } ).catch( ( error ) => {

      if ( error.code && error.code.indexOf( 'auth/cancelled-popup-request' ) > -1 ) return;

      this.getSocial( error.email );
    } );
  }

  onTwitter() {

    // check native
    if ( N.native() ) return N.loginTwitter();

    // get twitter
    this.firebase.auth.signInWithPopup( new auth.TwitterAuthProvider() ).then( ( user: auth.UserCredential ) => {

      // get token
      user.user.getIdToken().then( ( token: string ) => {

        // set twitter
        this.setTwitter( token );
      } );

    } ).catch( ( error ) => {

      if ( error.code && error.code.indexOf( 'auth/cancelled-popup-request' ) > -1 ) return;

      this.getSocial( error.email );
    } );
  }

  onFacebook() {

    // check native
    if ( N.native() ) return N.loginFacebook();

    // get facebook
    this.firebase.auth.signInWithPopup( new auth.FacebookAuthProvider() ).then( ( user: auth.UserCredential ) => {

      // get token
      user.user.getIdToken().then( ( token: string ) => {

        // set facebook
        this.setFacebook( token );
      } );

    } ).catch( ( error ) => {

      if ( error.code && error.code.indexOf( 'auth/cancelled-popup-request' ) > -1 ) return;

      this.getSocial( error.email );
    } );
  }
}