import {
  Component,
  OnInit
} from '@angular/core';
import {
  NgForm
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';
import {
  LOGINS
} from 'src/app/enum/enum';
import {
  AngularFireAuth
} from '@angular/fire/auth';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  NavigationService
} from 'src/app/services/navigation/navigation.service';
import {
  UserInfo,
  GetOutRequest,
  SetDropRequest,
  SetUserRequest,
  GetUserRequest,
} from 'src/app/services/http/http.classes';

declare var $: any;
declare var N: any;

@Component( {
  selector: 'app-user-infomation',
  templateUrl: './user-infomation.component.html',
  styleUrls: [ './user-infomation.component.css' ]
} )
export class UserInfomationComponent implements OnInit {

  public req: any;
  public res: any;

  constructor(
    private router: Router,
    private firebase: AngularFireAuth,
    private httpService: HttpService,
    private translateService: TranslateService,
    private navigationService: NavigationService,
  ) {

    this.req = {

      setUser: new SetUserRequest(),
      setDrop: new SetDropRequest(),
    }

    this.res = {

      userInfo: new UserInfo()
    }
  }

  ngOnInit() {

    // get user
    this.getUser();
  }

  // check
  get isMail() {

    return this.res.userInfo.confirmInfo.name && this.res.userInfo.confirmInfo.name.indexOf( 'Email' ) > -1;
  }

  get isSocial() {

    return this.res.userInfo.confirmInfo.name && this.res.userInfo.confirmInfo.name.indexOf( 'Email' ) < 0;
  }

  // http
  getOut() {

    this.httpService.getOut( new GetOutRequest() ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set session
      this.navigationService.getSession( Config.base.index.path );

      // set social
      this.setSocial();

    }, error => {

      this.httpService.setError();
    } );
  }

  getUser() {

    this.httpService.getUser( new GetUserRequest() ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.userInfo = response.userInfo;

    }, error => {

      this.httpService.setError();
    } );
  }

  setDrop( form: NgForm = null ) {

    // check valid
    if ( form && form.invalid ) return alert( this.translateService.instant( 'message.invalid.form' ) );

    // check confirm
    if ( confirm( this.translateService.instant( 'message.confirm.drop' ) ) == false ) return;

    // request
    this.httpService.setDrop( this.req.setDrop ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set session
      this.navigationService.getSession( Config.base.index.path );

      alert( this.translateService.instant( 'message.drop' ) );

      // check native
      if ( N.native() ) N.clearCacheAndCookie();

      // navigate
      return this.router.navigate( [ Config.base.index.path ] );
      
    }, error => {

      this.httpService.setError();
    } );
  }

  setSocial() {

    // check type
    switch ( this.res.userInfo.confirmInfo.type ) {

      case LOGINS.GOOGLE:
      case LOGINS.TWITTER:
      case LOGINS.FACEBOOK: {

        if ( N.native() ) {

          N.logoutFirebase();

        } else {

          // firebase out
          this.firebase.auth.signOut();
        }
      }
      default: {

        return this.router.navigate( [ Config.base.index.path ] );
      }
    }
  }

  setChange( form: NgForm, element: HTMLElement ) {

    // check valid
    if ( form.invalid ) return alert( this.translateService.instant( 'message.invalid.form' ) );

    // request
    this.httpService.setUser( this.req.setUser ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      // set focus
      this.onFocus( element );

    }, error => {

      this.httpService.setError();
    } );
  }

  // action
  onFocus( element: HTMLElement ) {

    // set remove
    $( '.data.active' ).removeClass( 'active' );

    // set active
    $( element ).addClass( 'active' );

    // init 
    this.req.setUser = new SetUserRequest();
    this.req.setDrop = new SetDropRequest();
  }

  onChange( form: NgForm, element: HTMLElement ) {

    // check valid
    if ( form.invalid ) return;

    // set chage
    this.setChange( form, element );
  }
}