import {
  Injectable
} from '@angular/core';
import {
  Router,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';

@Injectable()
export class SessionGuard implements CanActivate {
  constructor(
    private router: Router,
    private httpService: HttpService
  ) {}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Promise < boolean > {

    return new Promise( ( resolve ) => {

      this.httpService.getSession( null ).subscribe( response => {

        if ( response.code ) {

          this.router.navigate( [ Config.base.index.path ] );

          return resolve( false );
        } else {

          return resolve( true );
        }
      }, error => {

        this.httpService.setError();
      } );
    } );
  }
}