export class Result
{

	public static readonly CLIENT_SUCCESS = 0; // { "ko": "성공" }
	public static readonly CLIENT_SUCCESS_IN = 10; // { "ko": "로그인에 성공했습니다." }
	public static readonly CLIENT_SUCCESS_OUT = 20; // { "ko": "로그아웃을 완료했습니다." }
	public static readonly CLIENT_ERROR_AUTH_EMAIL = 100; // { "ko": "잘못된 인증메일입니다." }
	public static readonly CLIENT_ERROR_AUTH_EXPIRE = 110; // { "ko": "인증 시간이 만료된 메일입니다." }
	public static readonly CLIENT_ERROR_AUTH_REQUEST = 120; // { "ko": "유효한 인증메일이 있습니다. 메일함 혹은 스팸메일함을 확인하거나 한시간 후 다시 시도해주세요." }
	public static readonly CLIENT_ERROR_USER = 200; // { "ko": "가입된 회원이 아닙니다." }
	public static readonly CLIENT_ERROR_USER_AUTH = 210; // { "ko": "이메일 인증이 필요한 회원입니다." }
	public static readonly CLIENT_ERROR_USER_SIGN = 220; // { "ko": "이미 가입된 회원입니다." }
	public static readonly CLIENT_ERROR_USER_DROP = 230; // { "ko": "탈퇴된 회원입니다. 탈퇴 후 90일 이후에 재가입 하실 수 있습니다." }
	public static readonly CLIENT_ERROR_USER_BLOCK = 240; // { "ko": "차단된 사용자입니다." }
	public static readonly CLIENT_ERROR_USER_MATCH = 250; // { "ko": "잘못된 이메일 혹은 비밀번호를 입력했습니다." }
	public static readonly CLIENT_ERROR_USER_VALID = 260; // { "ko": "영문, 숫자, 특수문자를 포함한 6자리 이상 20자 이하의 비밀번호가 필요합니다." }
	public static readonly CLIENT_ERROR_USER_EMAIL = 270; // { "ko": "이메일로 가입된 계정입니다." }
	public static readonly CLIENT_ERROR_USER_GOOGLE = 280; // { "ko": "구글 로그인에 실패했습니다. 구글에서 인증된 메일인지 확인해주세요." }
	public static readonly CLIENT_ERROR_USER_SOCIAL = 290; // { "ko": "소셜 아이디로 가입된 회원입니다." }
	public static readonly CLIENT_ERROR_USER_INSERT = 300; // { "ko": "탈퇴 후 90일 이후에 재가입 하실 수 있습니다." }
	public static readonly CLIENT_ERROR_USER_TWITTER = 310; // { "ko": "트위터 로그인에 실패했습니다. 트위터에서 인증된 메일인지 확인해주세요." }
	public static readonly CLIENT_ERROR_USER_FACEBOOK = 320; // { "ko": "페이스북 로그인에 실패했습니다. 페이스북에서 인증된 메일인지 확인해주세요." }
	public static readonly CLIENT_ERROR_USER_PASSWORD = 330; // { "ko": "변경할 비밀번호가 일치하지 않습니다." }
	public static readonly CLIENT_ERROR_GOOGLE = 400; // { "ko": "구글로 가입된 회원입니다." }
	public static readonly CLIENT_ERROR_TWITTER = 410; // { "ko": "트위터로 가입된 회원입니다." }
	public static readonly CLIENT_ERROR_FACEBOOK = 420; // { "ko": "페이스북으로 가입된 회원입니다." }
	public static readonly CLIENT_ERROR_FIREBASE = 430; // { "ko": "SNS 로그인 모듈 로딩에 실패했습니다. 기술 지원팀에 문의해주세요." }
	public static readonly CLIENT_ERROR_NAME = 500; // { "ko": "20자 이하의 이름을 입력해주세요" }
	public static readonly CLIENT_ERROR_PHONE = 510; // { "ko": "20자 이하의 전화번호를 입력해주세요." }
	public static readonly CLIENT_ERROR_EMAIL = 520; // { "ko": "이메일 형식이 잘못되었거나 40자 이상의 이메일 주소가 입력되었습니다." }
	public static readonly CLIENT_ERROR_BOARD = 530; // { "ko": "존재하지 않는 게시물 입니다." }
	public static readonly CLIENT_ERROR_REPLY = 540; // { "ko": "이미 삭제된 댓글 입니다." }
	public static readonly CLIENT_ERROR_REPORT = 550; // { "ko": "이미 신고한 댓글 입니다." }
	public static readonly CLIENT_ERROR_POLL = 600; // { "ko": "잘못된 여론조사 입니다. 새로고침 후 이용해주세요." }
	public static readonly CLIENT_ERROR_POLL_USER = 610; // { "ko": "이미 참여한 여론조사 입니다." }
	public static readonly CLIENT_ERROR_POLL_DATE = 620; // { "ko": "기간이 종료된 여론조사입니다. 새로고침 후 이용해주세요." }
	public static readonly CLIENT_FAILED_VALID = 99993; // { "ko": "인가되지 않은 접근입니다. 기술 지원팀에 문의해주세요." }
	public static readonly CLIENT_FAILED_EMAIL = 99994; // { "ko": "인증메일 전송에 실패 했습니다." }
	public static readonly CLIENT_FAILED_PYTHON = 99995; // { "ko": "외부 프로그램 실행에 실패 했습니다. 기술 지원팀에 문의해주세요." }
	public static readonly CLIENT_FAILED_SESSION = 99996; // { "ko": "로그인 정보가 잘못 되었습니다. 재 로그인 후 진행해 주세요." }
	public static readonly CLIENT_FAILED_NETWORK = 99997; // { "ko": "인터넷 연결이 끊겼습니다." }
	public static readonly CLIENT_FAILED_UPLOADED = 99998; // { "ko": "클라우드에 이미지 업로드를 실패햇습니다. 기술 지원팀에 문의해주세요." }
	public static readonly CLIENT_FAILED_DATABASE = 99999; // { "ko": "데이터베이스 연결에 실패했습니다. 기술 지원팀에 문의해주세요." }
	
  public code: number;
  public message: string;
  
  constructor( data: any ) {

    this.code = data.code;
    this.message = data.message;
  }

	public static getCode( type: number ) : string {
		
		switch ( type ) {
			
			case 1: return 'ko';
			default: return 'ko';
		}
	}
	
	public static getMessage( code: any, type: number ) : string {
	
		code = parseInt( code );
		
		if ( this.getCode( type ).toLowerCase().indexOf('ko') > -1 ) {
		
			switch ( code ) {
				case 0: return '성공';
				case 10: return '로그인에 성공했습니다.';
				case 20: return '로그아웃을 완료했습니다.';
				case 100: return '잘못된 인증메일입니다.';
				case 110: return '인증 시간이 만료된 메일입니다.';
				case 120: return '유효한 인증메일이 있습니다. 메일함 혹은 스팸메일함을 확인하거나 한시간 후 다시 시도해주세요.';
				case 200: return '가입된 회원이 아닙니다.';
				case 210: return '이메일 인증이 필요한 회원입니다.';
				case 220: return '이미 가입된 회원입니다.';
				case 230: return '탈퇴된 회원입니다. 탈퇴 후 90일 이후에 재가입 하실 수 있습니다.';
				case 240: return '차단된 사용자입니다.';
				case 250: return '잘못된 이메일 혹은 비밀번호를 입력했습니다.';
				case 260: return '영문, 숫자, 특수문자를 포함한 6자리 이상 20자 이하의 비밀번호가 필요합니다.';
				case 270: return '이메일로 가입된 계정입니다.';
				case 280: return '구글 로그인에 실패했습니다. 구글에서 인증된 메일인지 확인해주세요.';
				case 290: return '소셜 아이디로 가입된 회원입니다.';
				case 300: return '탈퇴 후 90일 이후에 재가입 하실 수 있습니다.';
				case 310: return '트위터 로그인에 실패했습니다. 트위터에서 인증된 메일인지 확인해주세요.';
				case 320: return '페이스북 로그인에 실패했습니다. 페이스북에서 인증된 메일인지 확인해주세요.';
				case 330: return '변경할 비밀번호가 일치하지 않습니다.';
				case 400: return '구글로 가입된 회원입니다.';
				case 410: return '트위터로 가입된 회원입니다.';
				case 420: return '페이스북으로 가입된 회원입니다.';
				case 430: return 'SNS 로그인 모듈 로딩에 실패했습니다. 기술 지원팀에 문의해주세요.';
				case 500: return '20자 이하의 이름을 입력해주세요';
				case 510: return '20자 이하의 전화번호를 입력해주세요.';
				case 520: return '이메일 형식이 잘못되었거나 40자 이상의 이메일 주소가 입력되었습니다.';
				case 530: return '존재하지 않는 게시물 입니다.';
				case 540: return '이미 삭제된 댓글 입니다.';
				case 550: return '이미 신고한 댓글 입니다.';
				case 600: return '잘못된 여론조사 입니다. 새로고침 후 이용해주세요.';
				case 610: return '이미 참여한 여론조사 입니다.';
				case 620: return '기간이 종료된 여론조사입니다. 새로고침 후 이용해주세요.';
				case 99993: return '인가되지 않은 접근입니다. 기술 지원팀에 문의해주세요.';
				case 99994: return '인증메일 전송에 실패 했습니다.';
				case 99995: return '외부 프로그램 실행에 실패 했습니다. 기술 지원팀에 문의해주세요.';
				case 99996: return '로그인 정보가 잘못 되었습니다. 재 로그인 후 진행해 주세요.';
				case 99997: return '인터넷 연결이 끊겼습니다.';
				case 99998: return '클라우드에 이미지 업로드를 실패햇습니다. 기술 지원팀에 문의해주세요.';
				case 99999: return '데이터베이스 연결에 실패했습니다. 기술 지원팀에 문의해주세요.';
				default: return 'Unknown error';
			}
		}
		
		switch ( code ) {
			case 0: return '성공';
			case 10: return '로그인에 성공했습니다.';
			case 20: return '로그아웃을 완료했습니다.';
			case 100: return '잘못된 인증메일입니다.';
			case 110: return '인증 시간이 만료된 메일입니다.';
			case 120: return '유효한 인증메일이 있습니다. 메일함 혹은 스팸메일함을 확인하거나 한시간 후 다시 시도해주세요.';
			case 200: return '가입된 회원이 아닙니다.';
			case 210: return '이메일 인증이 필요한 회원입니다.';
			case 220: return '이미 가입된 회원입니다.';
			case 230: return '탈퇴된 회원입니다. 탈퇴 후 90일 이후에 재가입 하실 수 있습니다.';
			case 240: return '차단된 사용자입니다.';
			case 250: return '잘못된 이메일 혹은 비밀번호를 입력했습니다.';
			case 260: return '영문, 숫자, 특수문자를 포함한 6자리 이상 20자 이하의 비밀번호가 필요합니다.';
			case 270: return '이메일로 가입된 계정입니다.';
			case 280: return '구글 로그인에 실패했습니다. 구글에서 인증된 메일인지 확인해주세요.';
			case 290: return '소셜 아이디로 가입된 회원입니다.';
			case 300: return '탈퇴 후 90일 이후에 재가입 하실 수 있습니다.';
			case 310: return '트위터 로그인에 실패했습니다. 트위터에서 인증된 메일인지 확인해주세요.';
			case 320: return '페이스북 로그인에 실패했습니다. 페이스북에서 인증된 메일인지 확인해주세요.';
			case 330: return '변경할 비밀번호가 일치하지 않습니다.';
			case 400: return '구글로 가입된 회원입니다.';
			case 410: return '트위터로 가입된 회원입니다.';
			case 420: return '페이스북으로 가입된 회원입니다.';
			case 430: return 'SNS 로그인 모듈 로딩에 실패했습니다. 기술 지원팀에 문의해주세요.';
			case 500: return '20자 이하의 이름을 입력해주세요';
			case 510: return '20자 이하의 전화번호를 입력해주세요.';
			case 520: return '이메일 형식이 잘못되었거나 40자 이상의 이메일 주소가 입력되었습니다.';
			case 530: return '존재하지 않는 게시물 입니다.';
			case 540: return '이미 삭제된 댓글 입니다.';
			case 550: return '이미 신고한 댓글 입니다.';
			case 600: return '잘못된 여론조사 입니다. 새로고침 후 이용해주세요.';
			case 610: return '이미 참여한 여론조사 입니다.';
			case 620: return '기간이 종료된 여론조사입니다. 새로고침 후 이용해주세요.';
			case 99993: return '인가되지 않은 접근입니다. 기술 지원팀에 문의해주세요.';
			case 99994: return '인증메일 전송에 실패 했습니다.';
			case 99995: return '외부 프로그램 실행에 실패 했습니다. 기술 지원팀에 문의해주세요.';
			case 99996: return '로그인 정보가 잘못 되었습니다. 재 로그인 후 진행해 주세요.';
			case 99997: return '인터넷 연결이 끊겼습니다.';
			case 99998: return '클라우드에 이미지 업로드를 실패햇습니다. 기술 지원팀에 문의해주세요.';
			case 99999: return '데이터베이스 연결에 실패했습니다. 기술 지원팀에 문의해주세요.';
			default: return 'Unknown error';
		}
	}
}

// 페이지 정보
export class PageInfo {
	
	count: number = 0; // 갯수
	maximum: number = 0; // 페이지
	
	constructor( { count, maximum } : { count?, maximum? } = {} ) {
		
		if ( count != undefined ) this.count = count;
		if ( maximum != undefined ) this.maximum = maximum;
	}
	
	onInit( { count, maximum } : { count?, maximum? } = {} ) {
		if ( count != undefined ) this.count = count;
		if ( maximum != undefined ) this.maximum = maximum;
	}
}
// 번역 정보
export class TranslateInfo {
	
	translateType: number = 0; // 번역 고유번호
	manager: string = null; // 관리 이름
	code: string = null; // 코드
	
	constructor( { translateType, manager, code } : { translateType?, manager?, code? } = {} ) {
		
		if ( translateType != undefined ) this.translateType = translateType;
		if ( manager != undefined ) this.manager = manager;
		if ( code != undefined ) this.code = code;
	}
	
	onInit( { translateType, manager, code } : { translateType?, manager?, code? } = {} ) {
		if ( translateType != undefined ) this.translateType = translateType;
		if ( manager != undefined ) this.manager = manager;
		if ( code != undefined ) this.code = code;
	}
}
// 약관 정보
export class EulaInfo {
	
	eulaId: string = null; // 약관 고유번호
	manager: string = null; // 관리제목
	subject: string = null; // 제목
	content: string = null; // 내용
	
	constructor( { eulaId, manager, subject, content } : { eulaId?, manager?, subject?, content? } = {} ) {
		
		if ( eulaId != undefined ) this.eulaId = eulaId;
		if ( manager != undefined ) this.manager = manager;
		if ( subject != undefined ) this.subject = subject;
		if ( content != undefined ) this.content = content;
	}
	
	onInit( { eulaId, manager, subject, content } : { eulaId?, manager?, subject?, content? } = {} ) {
		if ( eulaId != undefined ) this.eulaId = eulaId;
		if ( manager != undefined ) this.manager = manager;
		if ( subject != undefined ) this.subject = subject;
		if ( content != undefined ) this.content = content;
	}
}
// 사용자 정보
export class UserInfo {
	
	userId: number = 0; // 사용자 고유번호
	name: string = null; // 이름
	email: string = null; // 이메일
	created: string = null; // 등록일
	confirmInfo: ConfirmInfo = new ConfirmInfo(); // JSON | 인증 정보
	
	constructor( { userId, name, email, created, confirmInfo } : { userId?, name?, email?, created?, confirmInfo? } = {} ) {
		
		if ( userId != undefined ) this.userId = userId;
		if ( name != undefined ) this.name = name;
		if ( email != undefined ) this.email = email;
		if ( created != undefined ) this.created = created;
		if ( confirmInfo != undefined ) this.confirmInfo = confirmInfo;
	}
	
	onInit( { userId, name, email, created, confirmInfo } : { userId?, name?, email?, created?, confirmInfo? } = {} ) {
		if ( userId != undefined ) this.userId = userId;
		if ( name != undefined ) this.name = name;
		if ( email != undefined ) this.email = email;
		if ( created != undefined ) this.created = created;
		if ( confirmInfo != undefined ) this.confirmInfo = confirmInfo;
	}
}
// 탈퇴 정보
export class DropInfo {
	
	created: string = null; // 탈퇴일
	expiration: string = null; // 만료일
	
	constructor( { created, expiration } : { created?, expiration? } = {} ) {
		
		if ( created != undefined ) this.created = created;
		if ( expiration != undefined ) this.expiration = expiration;
	}
	
	onInit( { created, expiration } : { created?, expiration? } = {} ) {
		if ( created != undefined ) this.created = created;
		if ( expiration != undefined ) this.expiration = expiration;
	}
}
// 차단 정보
export class BlockInfo {
	
	ended: string = null; // 종료일
	reason: string = null; // 사유
	started: string = null; // 시작일
	created: string = null; // 등록일
	
	constructor( { ended, reason, started, created } : { ended?, reason?, started?, created? } = {} ) {
		
		if ( ended != undefined ) this.ended = ended;
		if ( reason != undefined ) this.reason = reason;
		if ( started != undefined ) this.started = started;
		if ( created != undefined ) this.created = created;
	}
	
	onInit( { ended, reason, started, created } : { ended?, reason?, started?, created? } = {} ) {
		if ( ended != undefined ) this.ended = ended;
		if ( reason != undefined ) this.reason = reason;
		if ( started != undefined ) this.started = started;
		if ( created != undefined ) this.created = created;
	}
}
// 인증 정보
export class ConfirmInfo {
	
	type: number = 0; // 인증 분류
	name: string = null; // 인증 이름
	created: string = null; // 요청일
	requested: string = null; // 인증일
	
	constructor( { type, name, created, requested } : { type?, name?, created?, requested? } = {} ) {
		
		if ( type != undefined ) this.type = type;
		if ( name != undefined ) this.name = name;
		if ( created != undefined ) this.created = created;
		if ( requested != undefined ) this.requested = requested;
	}
	
	onInit( { type, name, created, requested } : { type?, name?, created?, requested? } = {} ) {
		if ( type != undefined ) this.type = type;
		if ( name != undefined ) this.name = name;
		if ( created != undefined ) this.created = created;
		if ( requested != undefined ) this.requested = requested;
	}
}
// 분야 정보
export class CategoryInfo {
	
	categoryType: number = 0; // 분야 분류
	path: string = null; // 경로
	name: string = null; // 제목
	sorted: number = 0; // 정렬
	manager: string = null; // 관리제목
	
	constructor( { categoryType, path, name, sorted, manager } : { categoryType?, path?, name?, sorted?, manager? } = {} ) {
		
		if ( categoryType != undefined ) this.categoryType = categoryType;
		if ( path != undefined ) this.path = path;
		if ( name != undefined ) this.name = name;
		if ( sorted != undefined ) this.sorted = sorted;
		if ( manager != undefined ) this.manager = manager;
	}
	
	onInit( { categoryType, path, name, sorted, manager } : { categoryType?, path?, name?, sorted?, manager? } = {} ) {
		if ( categoryType != undefined ) this.categoryType = categoryType;
		if ( path != undefined ) this.path = path;
		if ( name != undefined ) this.name = name;
		if ( sorted != undefined ) this.sorted = sorted;
		if ( manager != undefined ) this.manager = manager;
	}
}
// 데이터 정보
export class DataInfo {
	
	label: string = null; // 라벨
	title: string = null; // 라벨내용
	photo: string = null; // 작가
	editor: string = null; // 편집자
	opened: string = null; // 공개일
	subject: string = null; // 제목
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	editorInfos: EditorInfo[] = []; // JSON | 편집 목록
	
	constructor( { label, title, photo, editor, opened, subject, mediaInfos, editorInfos } : { label?, title?, photo?, editor?, opened?, subject?, mediaInfos?, editorInfos? } = {} ) {
		
		if ( label != undefined ) this.label = label;
		if ( title != undefined ) this.title = title;
		if ( photo != undefined ) this.photo = photo;
		if ( editor != undefined ) this.editor = editor;
		if ( opened != undefined ) this.opened = opened;
		if ( subject != undefined ) this.subject = subject;
		if ( mediaInfos != undefined ) this.mediaInfos = mediaInfos;
		if ( editorInfos != undefined ) this.editorInfos = editorInfos;
	}
	
	onInit( { label, title, photo, editor, opened, subject, mediaInfos, editorInfos } : { label?, title?, photo?, editor?, opened?, subject?, mediaInfos?, editorInfos? } = {} ) {
		if ( label != undefined ) this.label = label;
		if ( title != undefined ) this.title = title;
		if ( photo != undefined ) this.photo = photo;
		if ( editor != undefined ) this.editor = editor;
		if ( opened != undefined ) this.opened = opened;
		if ( subject != undefined ) this.subject = subject;
		if ( mediaInfos != undefined ) this.mediaInfos = mediaInfos;
		if ( editorInfos != undefined ) this.editorInfos = editorInfos;
	}
}
// 상태 정보
export class StatusInfo {
	
	marked: number = 0; // 마크수
	replied: number = 0; // 댓글수
	counted: number = 0; // 조회수
	reported: number = 0; // 신고수
	
	constructor( { marked, replied, counted, reported } : { marked?, replied?, counted?, reported? } = {} ) {
		
		if ( marked != undefined ) this.marked = marked;
		if ( replied != undefined ) this.replied = replied;
		if ( counted != undefined ) this.counted = counted;
		if ( reported != undefined ) this.reported = reported;
	}
	
	onInit( { marked, replied, counted, reported } : { marked?, replied?, counted?, reported? } = {} ) {
		if ( marked != undefined ) this.marked = marked;
		if ( replied != undefined ) this.replied = replied;
		if ( counted != undefined ) this.counted = counted;
		if ( reported != undefined ) this.reported = reported;
	}
}
// 편집 정보
export class EditorInfo {
	
	type: number = 0; // 분류 0: 텍스트, 1: 이미지, 2: 유튜브, 3: 여론조사
	width: number = 0; // 넓이
	height: number = 0; // 높이
	content: string = null; // 내용
	caption: string = null; // 표제
	identity: number = 0; // 고유번호
	
	constructor( { type, width, height, content, caption, identity } : { type?, width?, height?, content?, caption?, identity? } = {} ) {
		
		if ( type != undefined ) this.type = type;
		if ( width != undefined ) this.width = width;
		if ( height != undefined ) this.height = height;
		if ( content != undefined ) this.content = content;
		if ( caption != undefined ) this.caption = caption;
		if ( identity != undefined ) this.identity = identity;
	}
	
	onInit( { type, width, height, content, caption, identity } : { type?, width?, height?, content?, caption?, identity? } = {} ) {
		if ( type != undefined ) this.type = type;
		if ( width != undefined ) this.width = width;
		if ( height != undefined ) this.height = height;
		if ( content != undefined ) this.content = content;
		if ( caption != undefined ) this.caption = caption;
		if ( identity != undefined ) this.identity = identity;
	}
}
// 인덱스 정보
export class IndexInfo {
	
	indexType: number = 0; // 인덱스 분류
	sorted: number = 0; // 정렬
	counted: number = 0; // 갯수
	manager: string = null; // 이름
	
	constructor( { indexType, sorted, counted, manager } : { indexType?, sorted?, counted?, manager? } = {} ) {
		
		if ( indexType != undefined ) this.indexType = indexType;
		if ( sorted != undefined ) this.sorted = sorted;
		if ( counted != undefined ) this.counted = counted;
		if ( manager != undefined ) this.manager = manager;
	}
	
	onInit( { indexType, sorted, counted, manager } : { indexType?, sorted?, counted?, manager? } = {} ) {
		if ( indexType != undefined ) this.indexType = indexType;
		if ( sorted != undefined ) this.sorted = sorted;
		if ( counted != undefined ) this.counted = counted;
		if ( manager != undefined ) this.manager = manager;
	}
}
// 게시물 정보
export class BoardInfo {
	
	boardId: number = 0; // 게시물 고유번호
	relationId: number = 0; // 연관 고유번호
	path: string = null; // 경로
	marked: string = null; // 마크일
	created: string = null; // 등록일
	category: string = null; // 범주
	pollInfo: PollInfo = new PollInfo(); // JSON | 여론 정보
	dataInfo: DataInfo = new DataInfo(); // JSON | 데이터 정보
	statusInfo: StatusInfo = new StatusInfo(); // JSON | 상태 목록
	
	constructor( { boardId, relationId, path, marked, created, category, pollInfo, dataInfo, statusInfo } : { boardId?, relationId?, path?, marked?, created?, category?, pollInfo?, dataInfo?, statusInfo? } = {} ) {
		
		if ( boardId != undefined ) this.boardId = boardId;
		if ( relationId != undefined ) this.relationId = relationId;
		if ( path != undefined ) this.path = path;
		if ( marked != undefined ) this.marked = marked;
		if ( created != undefined ) this.created = created;
		if ( category != undefined ) this.category = category;
		if ( pollInfo != undefined ) this.pollInfo = pollInfo;
		if ( dataInfo != undefined ) this.dataInfo = dataInfo;
		if ( statusInfo != undefined ) this.statusInfo = statusInfo;
	}
	
	onInit( { boardId, relationId, path, marked, created, category, pollInfo, dataInfo, statusInfo } : { boardId?, relationId?, path?, marked?, created?, category?, pollInfo?, dataInfo?, statusInfo? } = {} ) {
		if ( boardId != undefined ) this.boardId = boardId;
		if ( relationId != undefined ) this.relationId = relationId;
		if ( path != undefined ) this.path = path;
		if ( marked != undefined ) this.marked = marked;
		if ( created != undefined ) this.created = created;
		if ( category != undefined ) this.category = category;
		if ( pollInfo != undefined ) this.pollInfo = pollInfo;
		if ( dataInfo != undefined ) this.dataInfo = dataInfo;
		if ( statusInfo != undefined ) this.statusInfo = statusInfo;
	}
}
// 댓글 정보
export class ReplyInfo {
	
	replyId: number = 0; // 댓글 고유번호
	boardId: number = 0; // 게시물 고유번호
	managerId: number = 0; // 관리자 고유번호
	openType: number = 0; // 공개 분류
	path: string = null; // 경로
	content: string = null; // 내용
	deleted: string = null; // 삭제일
	created: string = null; // 등록일
	category: string = null; // 분야 이름
	userInfo: UserInfo = new UserInfo(); // JSON | 사용자 정보
	dataInfo: DataInfo = new DataInfo(); // JSON | 게시물 정보
	statusInfo: StatusInfo = new StatusInfo(); // JSON | 상태 정보
	
	constructor( { replyId, boardId, managerId, openType, path, content, deleted, created, category, userInfo, dataInfo, statusInfo } : { replyId?, boardId?, managerId?, openType?, path?, content?, deleted?, created?, category?, userInfo?, dataInfo?, statusInfo? } = {} ) {
		
		if ( replyId != undefined ) this.replyId = replyId;
		if ( boardId != undefined ) this.boardId = boardId;
		if ( managerId != undefined ) this.managerId = managerId;
		if ( openType != undefined ) this.openType = openType;
		if ( path != undefined ) this.path = path;
		if ( content != undefined ) this.content = content;
		if ( deleted != undefined ) this.deleted = deleted;
		if ( created != undefined ) this.created = created;
		if ( category != undefined ) this.category = category;
		if ( userInfo != undefined ) this.userInfo = userInfo;
		if ( dataInfo != undefined ) this.dataInfo = dataInfo;
		if ( statusInfo != undefined ) this.statusInfo = statusInfo;
	}
	
	onInit( { replyId, boardId, managerId, openType, path, content, deleted, created, category, userInfo, dataInfo, statusInfo } : { replyId?, boardId?, managerId?, openType?, path?, content?, deleted?, created?, category?, userInfo?, dataInfo?, statusInfo? } = {} ) {
		if ( replyId != undefined ) this.replyId = replyId;
		if ( boardId != undefined ) this.boardId = boardId;
		if ( managerId != undefined ) this.managerId = managerId;
		if ( openType != undefined ) this.openType = openType;
		if ( path != undefined ) this.path = path;
		if ( content != undefined ) this.content = content;
		if ( deleted != undefined ) this.deleted = deleted;
		if ( created != undefined ) this.created = created;
		if ( category != undefined ) this.category = category;
		if ( userInfo != undefined ) this.userInfo = userInfo;
		if ( dataInfo != undefined ) this.dataInfo = dataInfo;
		if ( statusInfo != undefined ) this.statusInfo = statusInfo;
	}
}
// 연관 정보
export class RelationInfo {
	
	relationId: number = 0; // 연관 고유번호
	path: string = null; // 경로
	category: string = null; // 범주
	dataInfo: DataInfo = new DataInfo(); // JSON | 데이터 정보
	
	constructor( { relationId, path, category, dataInfo } : { relationId?, path?, category?, dataInfo? } = {} ) {
		
		if ( relationId != undefined ) this.relationId = relationId;
		if ( path != undefined ) this.path = path;
		if ( category != undefined ) this.category = category;
		if ( dataInfo != undefined ) this.dataInfo = dataInfo;
	}
	
	onInit( { relationId, path, category, dataInfo } : { relationId?, path?, category?, dataInfo? } = {} ) {
		if ( relationId != undefined ) this.relationId = relationId;
		if ( path != undefined ) this.path = path;
		if ( category != undefined ) this.category = category;
		if ( dataInfo != undefined ) this.dataInfo = dataInfo;
	}
}
// 배너 정보
export class BannerInfo {
	
	bannerId: number = 0; // 배너 고유번호
	link: string = null; // 링크
	subject: string = null; // 제목
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( { bannerId, link, subject, mediaInfos } : { bannerId?, link?, subject?, mediaInfos? } = {} ) {
		
		if ( bannerId != undefined ) this.bannerId = bannerId;
		if ( link != undefined ) this.link = link;
		if ( subject != undefined ) this.subject = subject;
		if ( mediaInfos != undefined ) this.mediaInfos = mediaInfos;
	}
	
	onInit( { bannerId, link, subject, mediaInfos } : { bannerId?, link?, subject?, mediaInfos? } = {} ) {
		if ( bannerId != undefined ) this.bannerId = bannerId;
		if ( link != undefined ) this.link = link;
		if ( subject != undefined ) this.subject = subject;
		if ( mediaInfos != undefined ) this.mediaInfos = mediaInfos;
	}
}
// 팝업 정보
export class PopupInfo {
	
	popupId: number = 0; // 배너 고유번호
	link: string = null; // 링크
	subject: string = null; // 제목
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( { popupId, link, subject, mediaInfos } : { popupId?, link?, subject?, mediaInfos? } = {} ) {
		
		if ( popupId != undefined ) this.popupId = popupId;
		if ( link != undefined ) this.link = link;
		if ( subject != undefined ) this.subject = subject;
		if ( mediaInfos != undefined ) this.mediaInfos = mediaInfos;
	}
	
	onInit( { popupId, link, subject, mediaInfos } : { popupId?, link?, subject?, mediaInfos? } = {} ) {
		if ( popupId != undefined ) this.popupId = popupId;
		if ( link != undefined ) this.link = link;
		if ( subject != undefined ) this.subject = subject;
		if ( mediaInfos != undefined ) this.mediaInfos = mediaInfos;
	}
}
// 광고 정보
export class AdInfo {
	
	adId: number = 0; // 광고 고유번호
	link: string = null; // 링크
	subject: string = null; // 제목
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( { adId, link, subject, mediaInfos } : { adId?, link?, subject?, mediaInfos? } = {} ) {
		
		if ( adId != undefined ) this.adId = adId;
		if ( link != undefined ) this.link = link;
		if ( subject != undefined ) this.subject = subject;
		if ( mediaInfos != undefined ) this.mediaInfos = mediaInfos;
	}
	
	onInit( { adId, link, subject, mediaInfos } : { adId?, link?, subject?, mediaInfos? } = {} ) {
		if ( adId != undefined ) this.adId = adId;
		if ( link != undefined ) this.link = link;
		if ( subject != undefined ) this.subject = subject;
		if ( mediaInfos != undefined ) this.mediaInfos = mediaInfos;
	}
}
// 여론 정보
export class PollInfo {
	
	pollId: number = 0; // 여론 고유번호
	ended: string = null; // 종료일
	sorted: number = 0; // 대표 여부
	closed: number = 0; // 종료 여부
	started: string = null; // 시작일
	created: string = null; // 등록일
	subject: string = null; // 제목
	selected: number = 0; // 선택 여부
	itemInfos: ItemInfo[] = []; // JSON | 항목 목록
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( { pollId, ended, sorted, closed, started, created, subject, selected, itemInfos, mediaInfos } : { pollId?, ended?, sorted?, closed?, started?, created?, subject?, selected?, itemInfos?, mediaInfos? } = {} ) {
		
		if ( pollId != undefined ) this.pollId = pollId;
		if ( ended != undefined ) this.ended = ended;
		if ( sorted != undefined ) this.sorted = sorted;
		if ( closed != undefined ) this.closed = closed;
		if ( started != undefined ) this.started = started;
		if ( created != undefined ) this.created = created;
		if ( subject != undefined ) this.subject = subject;
		if ( selected != undefined ) this.selected = selected;
		if ( itemInfos != undefined ) this.itemInfos = itemInfos;
		if ( mediaInfos != undefined ) this.mediaInfos = mediaInfos;
	}
	
	onInit( { pollId, ended, sorted, closed, started, created, subject, selected, itemInfos, mediaInfos } : { pollId?, ended?, sorted?, closed?, started?, created?, subject?, selected?, itemInfos?, mediaInfos? } = {} ) {
		if ( pollId != undefined ) this.pollId = pollId;
		if ( ended != undefined ) this.ended = ended;
		if ( sorted != undefined ) this.sorted = sorted;
		if ( closed != undefined ) this.closed = closed;
		if ( started != undefined ) this.started = started;
		if ( created != undefined ) this.created = created;
		if ( subject != undefined ) this.subject = subject;
		if ( selected != undefined ) this.selected = selected;
		if ( itemInfos != undefined ) this.itemInfos = itemInfos;
		if ( mediaInfos != undefined ) this.mediaInfos = mediaInfos;
	}
}
// 항목 정보
export class ItemInfo {
	
	itemId: number = 0; // 항목 고유번호
	subject: string = null; // 제목
	counted: number = 0; // 갯수
	created: string = null; // 집계일
	selected: number = 0; // 선택 여부
	percented: number = 0; // 퍼센트
	
	constructor( { itemId, subject, counted, created, selected, percented } : { itemId?, subject?, counted?, created?, selected?, percented? } = {} ) {
		
		if ( itemId != undefined ) this.itemId = itemId;
		if ( subject != undefined ) this.subject = subject;
		if ( counted != undefined ) this.counted = counted;
		if ( created != undefined ) this.created = created;
		if ( selected != undefined ) this.selected = selected;
		if ( percented != undefined ) this.percented = percented;
	}
	
	onInit( { itemId, subject, counted, created, selected, percented } : { itemId?, subject?, counted?, created?, selected?, percented? } = {} ) {
		if ( itemId != undefined ) this.itemId = itemId;
		if ( subject != undefined ) this.subject = subject;
		if ( counted != undefined ) this.counted = counted;
		if ( created != undefined ) this.created = created;
		if ( selected != undefined ) this.selected = selected;
		if ( percented != undefined ) this.percented = percented;
	}
}

// 인덱스 정보
export class GetIndexRequest {
	
	closed: string = null; // 닫은일
	constructor(
		closed?: string) {
		if( closed != undefined ) this.closed = closed;
	}
	
	onInit( { closed } : { closed? } = {} ) {
		if ( closed != undefined ) this.closed = closed;
	}
}

// 인덱스 정보
export class GetIndexResponse extends Result {
	
	pollInfos: BoardInfo[] = []; // JSON | 여론 목록
	newsInfos: BoardInfo[] = []; // JSON | 뉴스 목록
	videoInfos: BoardInfo[] = []; // JSON | 비디오 목록
	boardInfos: BoardInfo[] = []; // JSON | 게시물 목록
	popupInfos: PopupInfo[] = []; // JSON | 팝업 목록
	bannerInfos: BannerInfo[] = []; // JSON | 배너 목록
	
	constructor( data: any ) {
		super( data );
		this.pollInfos = data.pollInfos;
		this.newsInfos = data.newsInfos;
		this.videoInfos = data.videoInfos;
		this.boardInfos = data.boardInfos;
		this.popupInfos = data.popupInfos;
		this.bannerInfos = data.bannerInfos;
	}
}
// 분야 목록
export class GetCategoriesRequest {
	
	constructor() {
	}
	
	onInit( {  } : {  } = {} ) {
	}
}

// 분야 목록
export class GetCategoriesResponse extends Result {
	
	categoryInfos: CategoryInfo[] = []; // JSON | 분야 목록
	
	constructor( data: any ) {
		super( data );
		this.categoryInfos = data.categoryInfos;
	}
}
// 번역 목록
export class GetTranslatesRequest {
	
	constructor() {
	}
	
	onInit( {  } : {  } = {} ) {
	}
}

// 번역 목록
export class GetTranslatesResponse extends Result {
	
	translateInfos: TranslateInfo[] = []; // JSON | 번역 정보
	
	constructor( data: any ) {
		super( data );
		this.translateInfos = data.translateInfos;
	}
}
// 로그인
export class GetInRequest {
	
	email: string = null; // 이메일
	password: string = null; // 비밀번호
	constructor(
		email?: string,
		password?: string) {
		if( email != undefined ) this.email = email;
		if( password != undefined ) this.password = password;
	}
	
	onInit( { email, password } : { email?, password? } = {} ) {
		if ( email != undefined ) this.email = email;
		if ( password != undefined ) this.password = password;
	}
}

// 로그인
export class GetInResponse extends Result {
	
	userInfo: UserInfo = new UserInfo(); // JSON | 사용자 정보
	
	constructor( data: any ) {
		super( data );
		this.userInfo = data.userInfo;
	}
}
// 로그아웃
export class GetOutRequest {
	
	constructor() {
	}
	
	onInit( {  } : {  } = {} ) {
	}
}

// 로그아웃
export class GetOutResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 인증 요청
export class GetEmailRequest {
	
	email: string = null; // 이메일
	constructor(
		email?: string) {
		if( email != undefined ) this.email = email;
	}
	
	onInit( { email } : { email? } = {} ) {
		if ( email != undefined ) this.email = email;
	}
}

// 인증 요청
export class GetEmailResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 소셜 확인
export class GetSocialRequest {
	
	email: string = null; // 이메일
	constructor(
		email?: string) {
		if( email != undefined ) this.email = email;
	}
	
	onInit( { email } : { email? } = {} ) {
		if ( email != undefined ) this.email = email;
	}
}

// 소셜 확인
export class GetSocialResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 세션
export class GetSessionRequest {
	
	constructor() {
	}
	
	onInit( {  } : {  } = {} ) {
	}
}

// 세션
export class GetSessionResponse extends Result {
	
	user: number = 0; // 사용자 고유번호
	
	constructor( data: any ) {
		super( data );
		this.user = data.user;
	}
}
// 재설정 요청
export class GetPasswordRequest {
	
	email: string = null; // 이메일
	constructor(
		email?: string) {
		if( email != undefined ) this.email = email;
	}
	
	onInit( { email } : { email? } = {} ) {
		if ( email != undefined ) this.email = email;
	}
}

// 재설정 요청
export class GetPasswordResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 가입
export class SetUpRequest {
	
	email: string = null; // 이메일
	password: string = null; // 비밀번호
	passwordCheck: string = null; // 비밀번호 확인
	constructor(
		email?: string,
		password?: string,
		passwordCheck?: string) {
		if( email != undefined ) this.email = email;
		if( password != undefined ) this.password = password;
		if( passwordCheck != undefined ) this.passwordCheck = passwordCheck;
	}
	
	onInit( { email, password, passwordCheck } : { email?, password?, passwordCheck? } = {} ) {
		if ( email != undefined ) this.email = email;
		if ( password != undefined ) this.password = password;
		if ( passwordCheck != undefined ) this.passwordCheck = passwordCheck;
	}
}

// 가입
export class SetUpResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 탈퇴
export class SetDropRequest {
	
	password: string = null; // 비밀번호
	constructor(
		password?: string) {
		if( password != undefined ) this.password = password;
	}
	
	onInit( { password } : { password? } = {} ) {
		if ( password != undefined ) this.password = password;
	}
}

// 탈퇴
export class SetDropResponse extends Result {
	
	socialId: string = null; // 소셜 아이디
	
	constructor( data: any ) {
		super( data );
		this.socialId = data.socialId;
	}
}
// 인증
export class SetAuthRequest {
	
	auth: string = null; // 인증값
	email: string = null; // 이메일
	constructor(
		auth?: string,
		email?: string) {
		if( auth != undefined ) this.auth = auth;
		if( email != undefined ) this.email = email;
	}
	
	onInit( { auth, email } : { auth?, email? } = {} ) {
		if ( auth != undefined ) this.auth = auth;
		if ( email != undefined ) this.email = email;
	}
}

// 인증
export class SetAuthResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 수정
export class SetUserRequest {
	
	password: string = null; // 비밀번호
	passwordCheck: string = null; // 비밀번호 확인
	passwordUpdate: string = null; // 비밀번호 신규
	constructor(
		password?: string,
		passwordCheck?: string,
		passwordUpdate?: string) {
		if( password != undefined ) this.password = password;
		if( passwordCheck != undefined ) this.passwordCheck = passwordCheck;
		if( passwordUpdate != undefined ) this.passwordUpdate = passwordUpdate;
	}
	
	onInit( { password, passwordCheck, passwordUpdate } : { password?, passwordCheck?, passwordUpdate? } = {} ) {
		if ( password != undefined ) this.password = password;
		if ( passwordCheck != undefined ) this.passwordCheck = passwordCheck;
		if ( passwordUpdate != undefined ) this.passwordUpdate = passwordUpdate;
	}
}

// 수정
export class SetUserResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 토큰 등록
export class SetTokenRequest {
	
	token: string = null; // 토큰값
	device: string = null; // 운영체제
	locale: string = null; // 지역정보
	subscribe: number = 0; // 구독여부
	constructor(
		token?: string,
		device?: string,
		locale?: string,
		subscribe?: number) {
		if( token != undefined ) this.token = token;
		if( device != undefined ) this.device = device;
		if( locale != undefined ) this.locale = locale;
		if( subscribe != undefined ) this.subscribe = subscribe;
	}
	
	onInit( { token, device, locale, subscribe } : { token?, device?, locale?, subscribe? } = {} ) {
		if ( token != undefined ) this.token = token;
		if ( device != undefined ) this.device = device;
		if ( locale != undefined ) this.locale = locale;
		if ( subscribe != undefined ) this.subscribe = subscribe;
	}
}

// 토큰 등록
export class SetTokenResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 구글
export class SetGoogleRequest {
	
	idToken: string = null; // 토큰
	constructor(
		idToken?: string) {
		if( idToken != undefined ) this.idToken = idToken;
	}
	
	onInit( { idToken } : { idToken? } = {} ) {
		if ( idToken != undefined ) this.idToken = idToken;
	}
}

// 구글
export class SetGoogleResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 트위터
export class SetTwitterRequest {
	
	idToken: string = null; // 토큰
	constructor(
		idToken?: string) {
		if( idToken != undefined ) this.idToken = idToken;
	}
	
	onInit( { idToken } : { idToken? } = {} ) {
		if ( idToken != undefined ) this.idToken = idToken;
	}
}

// 트위터
export class SetTwitterResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 페이스북
export class SetFacebookRequest {
	
	idToken: string = null; // 토큰
	constructor(
		idToken?: string) {
		if( idToken != undefined ) this.idToken = idToken;
	}
	
	onInit( { idToken } : { idToken? } = {} ) {
		if ( idToken != undefined ) this.idToken = idToken;
	}
}

// 페이스북
export class SetFacebookResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 재설정
export class SetPasswordRequest {
	
	auth: string = null; // 인증값
	email: string = null; // 이메일
	password: string = null; // 비밀번호
	passwordCheck: string = null; // 비밀번호 확인
	constructor(
		auth?: string,
		email?: string,
		password?: string,
		passwordCheck?: string) {
		if( auth != undefined ) this.auth = auth;
		if( email != undefined ) this.email = email;
		if( password != undefined ) this.password = password;
		if( passwordCheck != undefined ) this.passwordCheck = passwordCheck;
	}
	
	onInit( { auth, email, password, passwordCheck } : { auth?, email?, password?, passwordCheck? } = {} ) {
		if ( auth != undefined ) this.auth = auth;
		if ( email != undefined ) this.email = email;
		if ( password != undefined ) this.password = password;
		if ( passwordCheck != undefined ) this.passwordCheck = passwordCheck;
	}
}

// 재설정
export class SetPasswordResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 약관 정보
export class GetEulaRequest {
	
	eulaId: number = 0; // 약관 고유번호
	constructor(
		eulaId?: number) {
		if( eulaId != undefined ) this.eulaId = eulaId;
	}
	
	onInit( { eulaId } : { eulaId? } = {} ) {
		if ( eulaId != undefined ) this.eulaId = eulaId;
	}
}

// 약관 정보
export class GetEulaResponse extends Result {
	
	eulaInfo: EulaInfo = new EulaInfo(); // JSON | 약관 정보
	
	constructor( data: any ) {
		super( data );
		this.eulaInfo = data.eulaInfo;
	}
}
// 약관 목록
export class GetEulasRequest {
	
	constructor() {
	}
	
	onInit( {  } : {  } = {} ) {
	}
}

// 약관 목록
export class GetEulasResponse extends Result {
	
	eulaInfos: EulaInfo[] = []; // JSON | 약관 목록
	
	constructor( data: any ) {
		super( data );
		this.eulaInfos = data.eulaInfos;
	}
}
// 사용자 정보
export class GetUserRequest {
	
	constructor() {
	}
	
	onInit( {  } : {  } = {} ) {
	}
}

// 사용자 정보
export class GetUserResponse extends Result {
	
	userInfo: UserInfo = new UserInfo(); // JSON | 사용자 정보
	
	constructor( data: any ) {
		super( data );
		this.userInfo = data.userInfo;
	}
}
// 사용자 마크 목록
export class GetMarksRequest {
	
	index: number = 0; // 현재값
	limit: number = 0; // 최대값
	constructor(
		index?: number,
		limit?: number) {
		if( index != undefined ) this.index = index;
		if( limit != undefined ) this.limit = limit;
	}
	
	onInit( { index, limit } : { index?, limit? } = {} ) {
		if ( index != undefined ) this.index = index;
		if ( limit != undefined ) this.limit = limit;
	}
}

// 사용자 마크 목록
export class GetMarksResponse extends Result {
	
	pageInfo: PageInfo = new PageInfo(); // JSON | 페이지 정보
	boardInfos: BoardInfo[] = []; // JSON | 게시물 목록
	
	constructor( data: any ) {
		super( data );
		this.pageInfo = data.pageInfo;
		this.boardInfos = data.boardInfos;
	}
}
// 사용자 댓글 목록
export class GetRepliesRequest {
	
	index: number = 0; // 현재값
	limit: number = 0; // 최대값
	constructor(
		index?: number,
		limit?: number) {
		if( index != undefined ) this.index = index;
		if( limit != undefined ) this.limit = limit;
	}
	
	onInit( { index, limit } : { index?, limit? } = {} ) {
		if ( index != undefined ) this.index = index;
		if ( limit != undefined ) this.limit = limit;
	}
}

// 사용자 댓글 목록
export class GetRepliesResponse extends Result {
	
	pageInfo: PageInfo = new PageInfo(); // JSON | 페이지 정보
	replyInfos: ReplyInfo[] = []; // JSON | 댓글 목록
	
	constructor( data: any ) {
		super( data );
		this.pageInfo = data.pageInfo;
		this.replyInfos = data.replyInfos;
	}
}
// 게시물 목록
export class GetBoardsRequest {
	
	categoryType: number = 0; // 범주 분류
	indexType: number = 0; // 인덱스 분류
	index: number = 0; // 현재값
	limit: number = 0; // 최대값
	query: string = null; // 검색어
	constructor(
		categoryType?: number,
		indexType?: number,
		index?: number,
		limit?: number,
		query?: string) {
		if( categoryType != undefined ) this.categoryType = categoryType;
		if( indexType != undefined ) this.indexType = indexType;
		if( index != undefined ) this.index = index;
		if( limit != undefined ) this.limit = limit;
		if( query != undefined ) this.query = query;
	}
	
	onInit( { categoryType, indexType, index, limit, query } : { categoryType?, indexType?, index?, limit?, query? } = {} ) {
		if ( categoryType != undefined ) this.categoryType = categoryType;
		if ( indexType != undefined ) this.indexType = indexType;
		if ( index != undefined ) this.index = index;
		if ( limit != undefined ) this.limit = limit;
		if ( query != undefined ) this.query = query;
	}
}

// 게시물 목록
export class GetBoardsResponse extends Result {
	
	pageInfo: PageInfo = new PageInfo(); // JSON | 페이지 정보
	boardInfos: BoardInfo[] = []; // JSON | 게시물 목록
	indexInfos: IndexInfo[] = []; // JSON | 인덱스 목록
	
	constructor( data: any ) {
		super( data );
		this.pageInfo = data.pageInfo;
		this.boardInfos = data.boardInfos;
		this.indexInfos = data.indexInfos;
	}
}
// 게시물 정보
export class GetBoardRequest {
	
	boardId: number = 0; // 게시물 고유번호
	constructor(
		boardId?: number) {
		if( boardId != undefined ) this.boardId = boardId;
	}
	
	onInit( { boardId } : { boardId? } = {} ) {
		if ( boardId != undefined ) this.boardId = boardId;
	}
}

// 게시물 정보
export class GetBoardResponse extends Result {
	
	boardInfo: BoardInfo = new BoardInfo(); // JSON | 게시물 정보
	pollInfos: PollInfo[] = []; // JSON | 여론 목록
	indexInfos: IndexInfo[] = []; // JSON | 인덱스 목록
	relationInfos: RelationInfo[] = []; // JSON | 연관 목록
	
	constructor( data: any ) {
		super( data );
		this.boardInfo = data.boardInfo;
		this.pollInfos = data.pollInfos;
		this.indexInfos = data.indexInfos;
		this.relationInfos = data.relationInfos;
	}
}
// 마크 등록, 삭제
export class SetMarkRequest {
	
	boardId: number = 0; // 게시물 고유번호
	constructor(
		boardId?: number) {
		if( boardId != undefined ) this.boardId = boardId;
	}
	
	onInit( { boardId } : { boardId? } = {} ) {
		if ( boardId != undefined ) this.boardId = boardId;
	}
}

// 마크 등록, 삭제
export class SetMarkResponse extends Result {
	
	boardInfo: BoardInfo = new BoardInfo(); // 게시물 정보
	
	constructor( data: any ) {
		super( data );
		this.boardInfo = data.boardInfo;
	}
}
// 댓글 목록
export class GetReplyListRequest {
	
	boardId: number = 0; // 게시물 고유번호
	index: number = 0; // 현재값
	limit: number = 0; // 최대값
	constructor(
		boardId?: number,
		index?: number,
		limit?: number) {
		if( boardId != undefined ) this.boardId = boardId;
		if( index != undefined ) this.index = index;
		if( limit != undefined ) this.limit = limit;
	}
	
	onInit( { boardId, index, limit } : { boardId?, index?, limit? } = {} ) {
		if ( boardId != undefined ) this.boardId = boardId;
		if ( index != undefined ) this.index = index;
		if ( limit != undefined ) this.limit = limit;
	}
}

// 댓글 목록
export class GetReplyListResponse extends Result {
	
	pageInfo: PageInfo = new PageInfo(); // JSON | 페이지 정보
	replyInfos: ReplyInfo[] = []; // JSON | 댓글 목록
	
	constructor( data: any ) {
		super( data );
		this.pageInfo = data.pageInfo;
		this.replyInfos = data.replyInfos;
	}
}
// 댓글 등록
export class SetReplyRequest {
	
	boardId: number = 0; // 게시물 고유번호
	content: string = null; // 내용
	constructor(
		boardId?: number,
		content?: string) {
		if( boardId != undefined ) this.boardId = boardId;
		if( content != undefined ) this.content = content;
	}
	
	onInit( { boardId, content } : { boardId?, content? } = {} ) {
		if ( boardId != undefined ) this.boardId = boardId;
		if ( content != undefined ) this.content = content;
	}
}

// 댓글 등록
export class SetReplyResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 댓글 수정
export class UpdReplyRequest {
	
	replyId: number = 0; // 댓글 고유번호
	content: string = null; // 내용
	constructor(
		replyId?: number,
		content?: string) {
		if( replyId != undefined ) this.replyId = replyId;
		if( content != undefined ) this.content = content;
	}
	
	onInit( { replyId, content } : { replyId?, content? } = {} ) {
		if ( replyId != undefined ) this.replyId = replyId;
		if ( content != undefined ) this.content = content;
	}
}

// 댓글 수정
export class UpdReplyResponse extends Result {
	
	replyInfo: ReplyInfo = new ReplyInfo(); // JSON | 댓글 정보
	
	constructor( data: any ) {
		super( data );
		this.replyInfo = data.replyInfo;
	}
}
// 댓글 삭제
export class DelReplyRequest {
	
	replyId: number = 0; // 댓글 고유번호
	constructor(
		replyId?: number) {
		if( replyId != undefined ) this.replyId = replyId;
	}
	
	onInit( { replyId } : { replyId? } = {} ) {
		if ( replyId != undefined ) this.replyId = replyId;
	}
}

// 댓글 삭제
export class DelReplyResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 댓글 신고
export class SetReportRequest {
	
	replyId: number = 0; // 댓글 고유번호
	constructor(
		replyId?: number) {
		if( replyId != undefined ) this.replyId = replyId;
	}
	
	onInit( { replyId } : { replyId? } = {} ) {
		if ( replyId != undefined ) this.replyId = replyId;
	}
}

// 댓글 신고
export class SetReportResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 문의 등록
export class SetContactRequest {
	
	name: string = null; // 이름
	email: string = null; // 이메일
	phone: string = null; // 전화번호
	content: string = null; // 내용
	constructor(
		name?: string,
		email?: string,
		phone?: string,
		content?: string) {
		if( name != undefined ) this.name = name;
		if( email != undefined ) this.email = email;
		if( phone != undefined ) this.phone = phone;
		if( content != undefined ) this.content = content;
	}
	
	onInit( { name, email, phone, content } : { name?, email?, phone?, content? } = {} ) {
		if ( name != undefined ) this.name = name;
		if ( email != undefined ) this.email = email;
		if ( phone != undefined ) this.phone = phone;
		if ( content != undefined ) this.content = content;
	}
}

// 문의 등록
export class SetContactResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 여론조사 등록
export class SetPollRequest {
	
	pollId: number = 0; // 여론 고유번호
	itemId: number = 0; // 항목 고유번호
	boardId: number = 0; // 게시물 고유번호
	constructor(
		pollId?: number,
		itemId?: number,
		boardId?: number) {
		if( pollId != undefined ) this.pollId = pollId;
		if( itemId != undefined ) this.itemId = itemId;
		if( boardId != undefined ) this.boardId = boardId;
	}
	
	onInit( { pollId, itemId, boardId } : { pollId?, itemId?, boardId? } = {} ) {
		if ( pollId != undefined ) this.pollId = pollId;
		if ( itemId != undefined ) this.itemId = itemId;
		if ( boardId != undefined ) this.boardId = boardId;
	}
}

// 여론조사 등록
export class SetPollResponse extends Result {
	
	pollInfo: PollInfo = new PollInfo(); // JSON | 여론조사 정보
	
	constructor( data: any ) {
		super( data );
		this.pollInfo = data.pollInfo;
	}
}
// 라우터 정보
export class GetRouteRequest {
	
	device: string = null; // 운영체제
	version: string = null; // 버전
	constructor(
		device?: string,
		version?: string) {
		if( device != undefined ) this.device = device;
		if( version != undefined ) this.version = version;
	}
	
	onInit( { device, version } : { device?, version? } = {} ) {
		if ( device != undefined ) this.device = device;
		if ( version != undefined ) this.version = version;
	}
}

// 라우터 정보
export class GetRouteResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 팝업 로그
export class SetPopupRequest {
	
	popupId: number = 0; // 팝업 고유번호
	constructor(
		popupId?: number) {
		if( popupId != undefined ) this.popupId = popupId;
	}
	
	onInit( { popupId } : { popupId? } = {} ) {
		if ( popupId != undefined ) this.popupId = popupId;
	}
}

// 팝업 로그
export class SetPopupResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 배너 로그
export class SetBannerRequest {
	
	bannerId: number = 0; // 배너 고유번호
	constructor(
		bannerId?: number) {
		if( bannerId != undefined ) this.bannerId = bannerId;
	}
	
	onInit( { bannerId } : { bannerId? } = {} ) {
		if ( bannerId != undefined ) this.bannerId = bannerId;
	}
}

// 배너 로그
export class SetBannerResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
// 광고 정보
export class GetAdRequest {
	
	constructor() {
	}
	
	onInit( {  } : {  } = {} ) {
	}
}

// 광고 정보
export class GetAdResponse extends Result {
	
	adInfo: AdInfo = new AdInfo(); // JSON | 광고 정보
	
	constructor( data: any ) {
		super( data );
		this.adInfo = data.adInfo;
	}
}
// 광고 로그
export class SetAdRequest {
	
	adId: number = 0; // 광고 고유번호
	constructor(
		adId?: number) {
		if( adId != undefined ) this.adId = adId;
	}
	
	onInit( { adId } : { adId? } = {} ) {
		if ( adId != undefined ) this.adId = adId;
	}
}

// 광고 로그
export class SetAdResponse extends Result {
	
	
	constructor( data: any ) {
		super( data );
	}
}
