import {
  OnInit,
  Component
} from '@angular/core';
import {
  Router,
  NavigationEnd
} from '@angular/router';
import {
  TranslateInfo,
  CategoryInfo
} from 'src/app/services/http/http.classes';
import {
  NavigationService,
} from 'src/app/services/navigation/navigation.service'
import {
  TranslatesService
} from 'src/app/services/translates/translates.service';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
declare var N: any;
declare var M: any;

@Component( {
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: [ './navigation.component.css' ]
} )

export class NavigationComponent implements OnInit {

  // init
  res: any;
  loaded: string;

  // event
  subscription: any;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private translatesService: TranslatesService,
    private navigationService: NavigationService,
  ) {

    // init
    this.res = {

      categoryInfos: new Array < CategoryInfo > ()
    }

    // evnet 
    this.subscription = {

      router: this.router.events.subscribe( ( event: NavigationEnd ) => {

        if ( ( event instanceof NavigationEnd ) == false ) {

          return;
        }

        // check loaded
        if (
          this.loaded &&
          this.loaded.indexOf( event.url ) > -1
        ) {
          
          // loaded
          this.translatesService.subscription.loaded.emit();
        } else {

          // set loaded
          this.loaded = event.url;

          // set router
          this.setRouter( event.url );
        }
      } ),

      navigation: this.navigationService.subscription.loaded.subscribe( ( url ) => {

        // set category
        this.res.categoryInfos = this.navigationService.getCategoryList;

        // set router
        this.setRouter( url || this.router.url );
      } )
    }
  }

  ngOnInit() {

    // get session
    this.navigationService.getSession();
  }

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  // check
  get isSession() {

    return this.navigationService.isSession();
  }

  // get
  getActive( index: number ) {

    return index == this.navigationService.getIndex;
  }

  get getIndex() {
    
    return this.navigationService.getIndex;
  }

  get getSocial() {

    var social = this.translateService.instant( 'social' );

    if ( social instanceof Array ) {

      return social;
    }

    return [];
  }

  get getTranslate() {

    return this.translatesService.getTranslate;
  }

  get getTranslateList() {

    return this.translatesService.getTranslateList;
  }

  // set
  setRouter( url: string ) {

    // scroll top
    window.scrollTo( 0, 0 );

    // return router
    return this.res.categoryInfos.some( ( categoryInfo: CategoryInfo ) => {

      // check path
      if ( url.indexOf( categoryInfo.manager ) > 0 ) {

        this.navigationService.setIndex( categoryInfo.categoryType );

        return true;
      }

      this.navigationService.setIndex();

      return false;
    } );
  }

  // action
  onSocial( url: string ) {

    if ( N.native() ) return N.callExternalBrowser( url );
    
    return window.open( url, '_blank' );
  }

  onTranslate( translateInfo: TranslateInfo ) {

    this.translatesService.setTranslate( translateInfo );
  }

  onNavigation( index: number ) {

    try {

      var instance = M.Sidenav.getInstance( $( '.sidenav' ) );

      instance.close();

    } catch ( error ) {}

    // check home
    this.navigationService.setIndex( index );

    // navigate router
    this.router.navigate( this.navigationService.getRoute );
  }
}