import {
  OnInit,
  Component
} from '@angular/core';
import {
  Params,
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  Config
} from 'src/app/app.config';
import {
  Result
} from 'src/app/services/http/http.classes';
import {
  TranslatesService
} from 'src/app/services/translates/translates.service';

@Component( {
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: [ './error.component.css' ]
} )
export class ErrorComponent implements OnInit {

  public parameter: Params;
  public subscription: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translatesService: TranslatesService
  ) {

    // event
    this.subscription = {

      route: this.route.params.subscribe( ( params: Params ) => {

        // set params
        this.parameter = params;

        // get popup
        var opener = window.opener || window.open( '', 'sign');

        if ( opener ) {

          // check code
          if ( this.parameter.code == Result.CLIENT_SUCCESS ) {

            // set parent
            opener.location.href = Config.base.index.path;

            // set close
            window.close();
          }
        }
      } )
    }
  }

  ngOnInit() {}

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  // get 
  get getMessage() {

    return Result.getMessage( this.parameter.code, this.translatesService.getTranslate.type );
  }

  // action
  onFocus() {

    var opener = window.opener || window.open( '', 'sign');

    // check popup
    if ( opener ) {

      // set close
      return window.close();
    }

    return this.router.navigate( [ Config.base.index.path ] );
  }
}