import {
  Component,
  OnInit
} from '@angular/core';
import {
  Config
} from 'src/app/app.config';
import {
  Router,
  ActivatedRoute,
  Params
} from '@angular/router';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  BoardInfo,
  GetBoardsRequest
} from 'src/app/services/http/http.classes';
import {
  TranslatesService
} from 'src/app/services/translates/translates.service';
import {
  PagerData
} from 'src/app/services/pagination/pagination.service';

@Component( {
  selector: 'app-notice-list',
  templateUrl: './notice-list.component.html',
  styleUrls: [ './notice-list.component.css' ]
} )
export class NoticeListComponent implements OnInit {

  public req: any;
  public res: any;
  public page: any;
  public subscription: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private translatesService: TranslatesService
  ) {

    // init
    this.req = {

      getBoards: new GetBoardsRequest( Config.category.notice )
    }

    this.res = {

      boardInfos: new Array < BoardInfo > ()
    }

    this.page = {

      content: new PagerData()
    }

    // event
    this.subscription = {

      query: this.route.queryParams.subscribe( ( params: Params ) => {

        // get boards
        return this.getBoards( params.page );
      } ),

      loaded: this.translatesService.subscription.loaded.subscribe( () => {

        // init
        this.req.getBoards.onInit( {

          page: 0
        } );

        // get boards
        return this.getBoards();
      } ),

      translate: this.translatesService.subscription.change.subscribe( () => {

        // get boards
        return this.getBoards();
      } )
    }
  }

  ngOnInit() {

    // get boards
    this.getBoards();
  }

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  // http
  getBoards( index: number = 1, limit: number = Config.limit.default ) {

    // set index
    this.req.getBoards.onInit( {

      index: limit * ( index - 1 ),
      limit: limit
    } );

    // request
    this.httpService.getBoards( this.req.getBoards ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.boardInfos = response.boardInfos;

      // set page
      this.page.content = new PagerData( response.pageInfo.maximum, index );

    }, error => {

      this.httpService.setError();
    } );
  }

  // action
  onBoards( page: number = 1 ) {

    return this.router.navigate( [ window.location.pathname ], {

      queryParams: Object.assign( {

        page: page
      } )
    } );
  }
}