import {
  OnInit,
  Component,
} from '@angular/core';
import {
  EulaInfo, GetEulasRequest
} from 'src/app/services/http/http.classes';
import {
  TranslatesService
} from 'src/app/services/translates/translates.service';
import { HttpService } from 'src/app/services/http/http.service';

@Component( {
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: [ './eula.component.css' ]
} )
export class EulaComponent implements OnInit {

  public res: any;
  public subscription: any;

  constructor(
    private httpService: HttpService,
    private translatesService: TranslatesService
  ) {

    // data
    this.res = {

      eulaInfos: new Array < EulaInfo > ()
    }

    // event
    this.subscription = {

      translate: this.translatesService.subscription.change.subscribe( () => {

        // get eulas
        this.getEulas();
      } )
    }
  }

  ngOnInit() {

    // get eulas
    this.getEulas();
  }
  
  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  // http
  getEulas() {

    this.httpService.getEulas( new GetEulasRequest() ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.eulaInfos = response.eulaInfos;

    }, error => {

      this.httpService.setError();
    })
  }
}