import {
  OnInit,
  Component
} from '@angular/core';
import {
  Config
} from 'src/app/app.config';
import {
  Router,
  Params,
  ActivatedRoute,
} from '@angular/router';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  AdInfo,
  IndexInfo,
  BoardInfo,
  EditorInfo,
  CategoryInfo,
  SetAdRequest,
  GetAdRequest,
  GetBoardsRequest,
} from 'src/app/services/http/http.classes';
import {
  PagerData
} from 'src/app/services/pagination/pagination.service';
import {
  TranslatesService
} from 'src/app/services/translates/translates.service';
import {
  NavigationService
} from 'src/app/services/navigation/navigation.service';

declare var $: any;
declare var N: any;

@Component( {
  selector: 'app-board-category',
  templateUrl: './board-category.component.html',
  styleUrls: [ './board-category.component.css' ]
} )
export class BoardCategoryComponent implements OnInit {

  public req: any;
  public res: any;
  public page: any;
  public subscription: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private translateService: TranslateService,
    private translatesService: TranslatesService,
    private navigationService: NavigationService
  ) {

    // init
    this.req = {

      getBoards: new GetBoardsRequest()
    }

    this.res = {

      adInfo: new AdInfo(),
      boardInfos: new Array < BoardInfo > (),
      indexInfos: new Array < IndexInfo > (),
      categoryInfo: new CategoryInfo(),
    }

    this.page = {

      content: new PagerData()
    }

    // event
    this.subscription = {

      route: this.route.params.subscribe( ( params: Params ) => {

        // get category
        var categoryInfo = this.navigationService.getCategory( params.category );

        // check category
        if ( categoryInfo ) {

          // set category
          this.req.getBoards.onInit( categoryInfo );

          // cehck equal
          if ( this.res.categoryInfo.categoryType == categoryInfo.categoryType ) return;

          // set response
          this.res.categoryInfo = categoryInfo;

          // get board
          return this.getBoards();
        }

        // error
        alert( this.translateService.instant( 'message.invalid.router' ) );

        return this.router.navigate( [ Config.base.index.path ] );
      } ),

      query: this.route.queryParams.subscribe( ( params: Params ) => {

        // check params
        if ( Object.keys( params ).empty() ) {

          return this.req.getBoards = new GetBoardsRequest( this.req.getBoards.categoryType );
        }

        // set index
        this.req.getBoards.query = params.query;
        this.req.getBoards.indexType = params.index;

        return this.getBoards( params.page );
      } ),

      loaded: this.translatesService.subscription.loaded.subscribe( () => {

        // init
        this.req.getBoards.onInit( {

          page: 0,
          index: 0,
          query: null,
        } );

        // get boards
        return this.getBoards();
      } ),

      translate: this.translatesService.subscription.change.subscribe( () => {

        // get ad
        this.getAd();

        // get boards
        return this.getBoards();
      } )
    }
  }

  ngOnInit() {

    // get ad
    this.getAd();
  }

  ngOnReady() {

    // check mobile
    if ( !this.navigationService.isMobile ) return;

    // get scroll
    var scroll = $( '#sub-top .row' );
    var active = $( '#sub-top .row .tag.active' );

    if ( scroll.length.empty() ) return;
    if ( active.length.empty() ) {

      // scroll animate
      scroll.stop().animate( {

        scrollLeft: 15
      }, 500 ).animate( {

        scrollLeft: 0
      }, 300 );

      return;
    }

    var screen = scroll.width();
    var position = scroll.scrollLeft() + active.position().left;

    if (
      scroll.scrollLeft() < position &&
      scroll.scrollLeft() + screen > position
    ) {

      return;
    }

    // set scroll
    scroll.scrollLeft( position );
  }

  ngOnTeads() {
    
    // remove iframe
    $( 'iframe' ).each( function() {

      if ( $( this ).attr( 'src' ).indexOf( Config.teads.name ) > -1 )  {

        $( this ).remove();
      }
    } )

    // set teads tag
    $.getScript( Config.teads.script, () => {} )
  }

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  // check 
  get isPoll() {

    return this.req.getBoards.categoryType == Config.category.poll;
  }

  get isVideo() {

    return this.req.getBoards.categoryType == Config.category.video;
  }

  get isBoard() {

    return this.isPoll == false;
  }

  // http
  getAd() {

    this.httpService.getAd( new GetAdRequest() ).subscribe( response => {

      if ( response.code ) return;

      this.res.adInfo = response.adInfo;

    }, error => {

      this.httpService.setError();
    } );
  }

  setAd( adId: number ) {

    this.httpService.setAd( new SetAdRequest( adId ) ).subscribe( response => {

      // do noting
    }, error => {

      this.httpService.setError();
    } );
  }

  getBoards( index: number = 1, limit = Config.limit.category ) {

    // set limit
    this.req.getBoards.onInit( {

      index: limit * ( index - 1 ),
      limit: limit
    } )

    // request
    this.httpService.getBoards( this.req.getBoards ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.boardInfos = response.boardInfos;
      this.res.indexInfos = response.indexInfos;

      // check video
      if ( this.isVideo ) {

        // get board
        this.res.boardInfos.forEach( ( boardInfo: BoardInfo ) => {

          // get media
          boardInfo.dataInfo.mediaInfos.forEach( ( mediaInfo: EditorInfo ) => {

            mediaInfo.content = Config.youtube.image + mediaInfo.content.replace( Config.youtube.embed, '' ) + Config.youtube.quality[ 1 ];
          } )
        } )
      }

      // set page
      this.page.content = new PagerData( response.pageInfo.maximum, index );

    }, error => {

      this.httpService.setError();
    } )
  }

  // action
  onAd() {

    this.setAd( this.res.adInfo.adId );

    if ( N.native() ) return N.callExternalBrowser( this.res.adInfo.link );

    return window.open( this.res.adInfo.link, '_blank' );
  }

  onBoards( page: number = 1, index: number = 0 ) {

    return this.router.navigate( [ window.location.pathname ], {

      queryParams: Object.assign( {

        page: page,
        query: this.req.getBoards.query,
        index: this.req.getBoards.indexType != index ? index : 0
      } )
    } );
  }
}