import {
  Input,
  Output,
  Directive,
  EventEmitter,
} from '@angular/core';

@Directive( {
  selector: '[readyInit]'
} )
export class ReadyInit {

  @Input( 'readyInit' ) ready: boolean = false;
  @Output( 'readyEvent' ) event: EventEmitter < any > = new EventEmitter();

  constructor() {}

  ngAfterViewInit() {

    // check ready
    if ( this.ready ) {

      this.event.emit();
    }
  }
}