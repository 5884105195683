import {
  Component,
  OnInit
} from '@angular/core';
import {
  NgForm
} from '@angular/forms';
import {
  Router,
  Params,
  ActivatedRoute,
} from '@angular/router';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  SetPasswordRequest
} from 'src/app/services/http/http.classes';

@Component( {
  selector: 'app-sign-password',
  templateUrl: './sign-password.component.html',
  styleUrls: [ './sign-password.component.css' ]
} )
export class SignPasswordComponent implements OnInit {

  public req: any;
  public subscription: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private translateService: TranslateService
  ) {

    // init
    this.req = {

      setPassword: new SetPasswordRequest()
    }

    // set router
    this.subscription = {

      route: this.route.params.subscribe( ( params: Params ) => {

        // set params
        this.req.setPassword.onInit( params );
      } )
    }
  }

  ngOnInit() {}

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  // http
  setSubmit( form: NgForm ) {

    // check form
    if ( form.invalid ) return alert( this.translateService.instant( 'message.invalid.form' ) );

    // request
    this.httpService.setPassword( this.req.setPassword ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      alert( this.translateService.instant( 'message.password' ) );

      return this.router.navigate( [ Config.page.sign.in.path ] );
    }, error => {

      this.httpService.setError();
    } )
  }

  // action
  onSubmit( form: NgForm ) {

    // check form
    if ( form.invalid ) return;

    // set submit
    this.setSubmit( form );
  }
}