import {
  OnInit,
  Component,
} from '@angular/core';
import {
  Params,
  ActivatedRoute,
  Router,
} from '@angular/router';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  SetAuthRequest,
  Result
} from 'src/app/services/http/http.classes';
import {
  TranslatesService
} from 'src/app/services/translates/translates.service';

@Component( {
  selector: 'app-sign-confirm',
  templateUrl: './sign-confirm.component.html',
  styleUrls: [ './sign-confirm.component.css' ]
} )
export class SignConfirmComponent implements OnInit {

  public req: any;
  public res: any;
  public subscription: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private translateService: TranslateService,
    private translatesService: TranslatesService
  ) {

    // init
    this.req = {

      setAuth: new SetAuthRequest()
    }

    this.res = {

      code: 0
    }

    // set router
    this.subscription = {

      route: this.route.params.subscribe( ( params: Params ) => {

        // set params
        this.req.setAuth.onInit( params );

        // set auth
        this.setAuth();
      } )
    }
  }

  ngOnInit() {}

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  // get
  get getMessage() {

    if ( this.res.code ) {

      return Result.getMessage( this.res.code, this.translatesService.getTranslate.type );
    }

    return this.translateService.instant( 'message.confirm.user' )
  }

  // http
  setAuth() {

    this.httpService.setAuth( this.req.setAuth ).subscribe( response => {

      // set code
      this.res.code = response.code;
      
    }, error => {

      this.httpService.setError();
    } );
  }

  // action
  onFocus( login: boolean ) {

    return this.router.navigate( [ login ? Config.page.sign.in.path : Config.base.index.path ] );
  }
}